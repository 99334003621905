import React from 'react';

import useWindowSize from '../../../hooks/useWindowSize';
import CommonButton from '../../CommonButton/CommonButton';

import styles from './ActiveBetActions.module.scss';

type ActiveBetActionsProps = {
  onCashOut: () => void;
  bet?: any;
  loading: boolean;
};

export default function ActiveBetCashOutButton({ onCashOut, bet, loading }: ActiveBetActionsProps) {
  const { isMobile } = useWindowSize();

  return (
    <div className={styles.buttonsContainer}>
      <CommonButton
        type='primaryGreen'
        onClick={onCashOut}
        label={loading ? 'Loading' : 'Cash out'}
        height={34}
        width={isMobile ? 93 : 115}
        loading={loading}
        className={styles.button}
      />
    </div>
  );
}
