import React from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

import lottieData from '../../../assets/animations/step4.lottie';
import useWindowSize from '../../../hooks/useWindowSize';

import styles from '../Step.module.scss';

export default function Step4() {
  const { isMobile } = useWindowSize();
  return (
    <div className={styles.step}>
      <div className={styles.step_left}>
        <div className={styles.step_left_label}>Step 4</div>
        <h2 className={styles.step_left_title}>Place bet and Cash out</h2>
        <div className={styles.step_left_text}>
          After selecting the price direction, wager, and multiplier, <b>place your bet.</b> You can then watch the live
          price chart to monitor how your bet is performing.
        </div>
        <div className={styles.step_left_text}>
          You can <b>cash out</b> early if you're in profit or wait for the price to reach your predicted point or
          “bust” if it moves against you.
        </div>
      </div>

      <div className={styles.lottie}>
        <DotLottieReact
          src={lottieData}
          loop
          autoplay
          style={{ width: isMobile ? '100%' : '400px', height: isMobile ? '100%' : '230px' }}
        />
      </div>
    </div>
  );
}
