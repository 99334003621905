import React from 'react';
import { RadioChangeEvent } from 'antd';

import CommonButton from '../../CommonButton/CommonButton';
import PriceWillGo from '../../PriceWillGo/PriceWillGo';

import DashboardField from './DashboardField/DashboardField';
import RadioBlock from './RadioBlock/RadioBlock';

import styles from './Dashboard.module.scss';

interface ControlBoardProps {
  wager: string;
  priceWillGo: string;
  multiplier: string;
  entryPrice: number;
  customPriceMove: string;
  selected: string;
  onChangeWager: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangePriceWillGo: (value: string) => void;
  onChangeMultiplier: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeCustomPriceMove: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeRadio: (e: RadioChangeEvent) => void;
  onResetFields: () => void;
}

export default function Dashboard({
  wager,
  priceWillGo,
  multiplier,
  entryPrice,
  customPriceMove,
  selected,
  onChangeWager,
  onChangePriceWillGo,
  onChangeMultiplier,
  onChangeCustomPriceMove,
  onChangeRadio,
  onResetFields,
}: ControlBoardProps) {
  return (
    <div className={styles.dashboard}>
      <DashboardField label={'Entry price'} prefix={'$'} value={entryPrice} />
      <div className={styles.item}>
        <PriceWillGo priceWillGo={priceWillGo} onChange={onChangePriceWillGo} />
      </div>
      <div className={styles.item}>
        <DashboardField
          label={'Wager'}
          prefix={'$'}
          value={wager}
          error={!(parseFloat(wager.replace(/,/g, '')) > 0)}
          errorText={'Minimum wager is 0.01'}
          onChange={onChangeWager}
        />
      </div>
      <div className={styles.item}>
        <DashboardField
          label={'Multiplier'}
          prefix={'X'}
          value={multiplier}
          thousandSeparator={false}
          decimalScale={0}
          maxValue={1000}
          error={!(+multiplier > 0)}
          errorText={'Minimum multiplier is 1'}
          onChange={onChangeMultiplier}
        />
      </div>
      <div className={styles.item}>
        <DashboardField
          label={'Custom price move,%'}
          value={customPriceMove}
          decimalScale={0}
          allowNegative
          minValue={-100}
          onChange={onChangeCustomPriceMove}
        />
      </div>
      <div className={styles.item}>
        <RadioBlock selected={selected} onChangeRadio={onChangeRadio} />
      </div>
      <div className={styles.item}>
        <CommonButton type='secondary' onClick={onResetFields} label='Reset fields' />
      </div>
    </div>
  );
}
