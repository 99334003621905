import React, { useCallback, useState } from 'react';
import { Drawer } from 'antd';

import { BookIcon, CalculatorIcon, WarningIcon } from '../../assets/icons';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useAppDispatch } from '../../store';
import { setMenuDrawerVisible } from '../../store/ui/slices';

import styles from './MenuDrawer.module.scss';

type MenuDriverProps = {
  onOpenHowItWorksModal: () => void;
  onOpenRiskWarningModal: () => void;
  onOpenRoiCalculatorModal: () => void;
};
export default function MenuDrawer({
  onOpenHowItWorksModal,
  onOpenRiskWarningModal,
  onOpenRoiCalculatorModal,
}: MenuDriverProps) {
  const { menuDrawerVisible } = useTypedSelector((state) => state.ui);
  const dispatch = useAppDispatch();
  const [, setPosition] = useState<number | null>(null);

  const closeDriver = useCallback(() => {
    dispatch(setMenuDrawerVisible(false));
  }, [dispatch]);

  const onChangePosition = (y: number) => {
    setPosition((start) => {
      if (!start) {
        return y;
      } else if (y > start + 15) {
        closeDriver();
        return null;
      }
      return start;
    });
  };
  const handleTouchStart = () => {
    const handleTouchMove = (e: TouchEvent) => onChangePosition(e.touches[0].clientY);
    const handleTouchEnd = () => {
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
      setPosition(null);
    };

    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);
  };

  const openWarningModal = useCallback(() => {
    closeDriver();
    onOpenRiskWarningModal();
  }, [closeDriver, onOpenRiskWarningModal]);

  const openHowItWorksModal = useCallback(() => {
    closeDriver();
    onOpenHowItWorksModal();
  }, [closeDriver, onOpenHowItWorksModal]);

  const openRoiCalculator = useCallback(() => {
    closeDriver();
    onOpenRoiCalculatorModal();
  }, [closeDriver, onOpenRoiCalculatorModal]);

  return (
    <Drawer
      className={styles.driver}
      open={menuDrawerVisible}
      onClose={closeDriver}
      placement='bottom'
      closable={false}
      height={175}
    >
      <div className={styles.draggerArea} onTouchStart={handleTouchStart}>
        <span className={styles.bar}></span>
      </div>
      <div className={styles.menu}>
        <button className={styles.menu_item} onClick={openHowItWorksModal}>
          <BookIcon />
          How it works
        </button>
        <button className={styles.menu_item} onClick={openRoiCalculator}>
          <CalculatorIcon />
          ROI Calculator
        </button>
        <button className={styles.menu_item} onClick={openWarningModal}>
          <WarningIcon />
          Risk Warning
        </button>
      </div>
    </Drawer>
  );
}
