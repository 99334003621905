import React, { useMemo } from 'react';
import { numericFormatter } from 'react-number-format';
import Icon from '@ant-design/icons';
import { Tooltip } from 'antd';

import { InfoIcon } from '../../../assets/icons';
import { ActiveBet } from '../../../types/entities';

import styles from './ActiveBetFundingCell.module.scss';

type Props = {
  bet: ActiveBet;
};

export default function ActiveBetFundingCell({ bet }: Props) {
  const fee = useMemo(() => {
    if (bet.fundingFeeAmount && !Number.isNaN(bet.fundingFeeAmount)) {
      // return bet.amount * bet.fundingFeePercent;
      return numericFormatter(String(bet.fundingFeeAmount), {
        thousandSeparator: true,
        decimalScale: 2,
        fixedDecimalScale: true,
        prefix: '$',
      });
    }
    return '-';
  }, [bet]);

  return (
    <span className={styles.wrapper}>
      {fee}
      <Tooltip
        trigger='click'
        title={
          <span className={styles.tooltipText}>
            The Funding Fee is a periodic charge applied after a specific time interval that your trade remains open. It
            is a fixed percentage applied uniformly across all trades to maintain fairness and encourage timely
            decisions.
          </span>
        }
      >
        <Icon component={InfoIcon} className={styles.infoIcon} />
      </Tooltip>
    </span>
  );
}
