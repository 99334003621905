import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';

import { requester } from '../../../api/axiosInstance';
import { IBetRequest, IBetResponse } from '../types';

export const openBet = createAsyncThunk<IBetResponse, IBetRequest, { rejectValue: { status: number; data: any } }>(
  '/bet/openBet',
  async (body: IBetRequest, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IBetResponse> = await requester.post('/bets/open', body);
      return response.data; // успешный результат
    } catch (err) {
      // Проверяем, является ли ошибка экземпляром AxiosError
      if (err instanceof AxiosError && err.response) {
        const { status, data } = err.response;
        return rejectWithValue({ status, data }); // возвращаем объект ошибки
      }
      throw err; // пробрасываем ошибку, если это не AxiosError
    }
  },
);
