import { Prediction } from '../types/entities';

export const getAutoBetErrorForTake = (entryPrice: number, priceWillGo: string, takePrice: string) => {
  if (!takePrice) return { isErrorTakeCurrentPrice: false, errorTakeCurrentPriceText: '' };
  const numberTakePrice = parseFloat(takePrice.toString().replace(/,/g, ''));

  const isPriceUp = priceWillGo === Prediction.UP;
  const priceError = isPriceUp ? numberTakePrice < entryPrice : numberTakePrice > entryPrice;

  return {
    isErrorTakeCurrentPrice: !!priceError,
    errorTakeCurrentPriceText: 'Trigger price is too close to current price',
  };
};

export const getAutoBetErrorForClose = (
  entryPrice: number,
  priceWillGo: string,
  closePrice: string,
  closeLoss: string,
  bustPrice: string | number,
) => {
  const isPriceUp = priceWillGo === Prediction.UP;
  const numberClosePrice = parseFloat(closePrice.toString().replace(/,/g, ''));

  const closePriceError =
    closePrice && ((isPriceUp && numberClosePrice >= entryPrice) || (!isPriceUp && numberClosePrice <= entryPrice));

  const closeLossError =
    (isPriceUp && closeLoss && (closePrice || 0) < bustPrice) ||
    (!isPriceUp && closeLoss && (closePrice || 0) > bustPrice) ||
    (!closePrice && closeLoss);

  const errorBustPrice = (isPriceUp && numberClosePrice < +bustPrice) || (!isPriceUp && numberClosePrice > +bustPrice);

  return {
    isErrorCloseCurrentPrice: !!closePriceError,
    isErrorCloseBustPrice: !!errorBustPrice,
    isErrorCloseLoss: !!closeLossError,
    errorCloseText: closePriceError
      ? 'Trigger price is too close to current price'
      : errorBustPrice
        ? 'Trigger price is too close to bust price'
        : closeLossError
          ? 'Stop loss price cannot be through bust price'
          : '',
  };
};
