import React from 'react';
import { Segmented } from 'antd';
import cn from 'classnames';

import { ArrowUpIcon } from '../../assets/icons';
import { Prediction } from '../../types/entities';

import styles from './PriceWillGo.module.scss';

const options = [
  {
    label: (
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 8,
        }}
      >
        <ArrowUpIcon style={{ marginTop: 1 }} /> Up
      </span>
    ),
    value: Prediction.UP,
  },
  {
    label: (
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 8,
        }}
      >
        <ArrowUpIcon style={{ marginTop: 1, transform: 'rotate(180deg)' }} /> Down
      </span>
    ),
    value: Prediction.DOWN,
  },
];

interface PriceWillGoProps {
  priceWillGo: string;
  onChange: (value: string) => void;
}

export default function PriceWillGo({ priceWillGo, onChange }: PriceWillGoProps) {
  return (
    <>
      <div className={styles.label}>Price will go</div>
      <div className={cn(styles.segmentedWrapper, priceWillGo === Prediction.DOWN && styles.segmentedWrapper_down)}>
        <Segmented options={options} value={priceWillGo} onChange={onChange} />
      </div>
    </>
  );
}
