import React from 'react';
import cn from 'classnames';

import styles from './Loader.module.scss';

type LoaderProps = {
  isLoading: boolean;
  isInner?: boolean;
};

export default function TableLoader({ isLoading, isInner = true }: LoaderProps) {
  if (!isLoading) {
    return null;
  }
  return (
    <div className={cn(styles.loader, isInner && styles.loader_inner)}>
      <div className={styles.loader}></div>
    </div>
  );
}
