import React, { useMemo } from 'react';
import { NumericFormat } from 'react-number-format';

type FormattedAmountProps = {
  amount: number;
  showCurrency?: boolean;
};

export default function FormattedAmountShort({ amount, showCurrency = true }: FormattedAmountProps) {
  const postfix = useMemo(() => {
    if (amount >= 500000) {
      return 'M';
    }
    if (amount >= 1000) {
      return 'K';
    }
    return '';
  }, [amount]);

  const baseNum = useMemo(() => {
    if (amount < 1000) {
      return amount;
    }
    if (amount < 500000) {
      return amount / 1000;
    }
    return amount / 1000000;
  }, [amount]);

  return (
    <NumericFormat
      thousandSeparator={true}
      decimalScale={2}
      fixedDecimalScale={false}
      prefix={showCurrency ? '$' : ''}
      placeholder=''
      value={baseNum}
      displayType='text'
      suffix={postfix}
    />
  );
}
