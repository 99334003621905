import React, { useCallback } from 'react';
import Icon from '@ant-design/icons';
import { Tooltip } from 'antd';

import { InfoIcon, LinkIcon } from '../../assets/icons';
import useWindowSize from '../../hooks/useWindowSize';
import { useAppDispatch } from '../../store';
import { setSelectedBet } from '../../store/betList/slices';
import { setBetInfoModalVisible } from '../../store/ui/slices';
import { ClosedBet } from '../../types/entities';

import styles from './ClosedBetActions.module.scss';

type ClosedBetActionsProps = {
  bet: ClosedBet;
};

export default function ClosedBetActions({ bet }: ClosedBetActionsProps) {
  const dispatch = useAppDispatch();
  const { isMobile } = useWindowSize();

  const onInfo = useCallback(() => {
    dispatch(setSelectedBet(bet));
    console.log({ bet });
    dispatch(setBetInfoModalVisible(true));
  }, [dispatch, bet]);

  const onCopyLink = useCallback(() => {
    console.log(bet);
  }, [dispatch, bet]);

  return (
    <div className={styles.buttonsContainer} id={`${bet?.id}bet`}>
      {isMobile ? (
        <button className={styles.iconButton} onClick={onInfo}>
          <Icon component={InfoIcon} className={styles.icon} />
        </button>
      ) : (
        <Tooltip title={'Bet Details'}>
          <button className={styles.iconButton} onClick={onInfo}>
            <Icon component={InfoIcon} className={styles.icon} />
          </button>
        </Tooltip>
      )}

      <button className={styles.iconButton} onClick={onCopyLink}>
        <Tooltip title='Copy bet link'>
          <Icon component={LinkIcon} className={styles.icon} />
        </Tooltip>
      </button>
    </div>
  );
}
