import React from 'react';

import { useTypedSelector } from '../../../hooks/useTypedSelector';
import useWindowSize from '../../../hooks/useWindowSize';
import FormattedAmount from '../../FormattedAmount/FormattedAmount';
import FormattedAmountShort from '../../FormattedAmount/FormattedAmountShort';

import styles from './AssetHistoricalData.module.scss';

export default function AssetHistoricalData() {
  const { isMobile } = useWindowSize();
  const { currentAsset } = useTypedSelector((state) => state.assets);

  return (
    <div className={styles.wrapper}>
      {isMobile && <span>{currentAsset?.name}</span>}
      {isMobile && <span>/</span>}
      <span>24h</span>
      <span>
        Vol:{' '}
        <FormattedAmountShort
          amount={currentAsset?.volume24h ? Number(currentAsset?.volume24h) : 0}
          showCurrency={true}
        />
      </span>
      <span>/</span>
      <span>
        H: <FormattedAmount amount={Number(currentAsset?.high)} showCurrency={false} />
      </span>
      <span>/</span>
      <span>
        L: <FormattedAmount amount={Number(currentAsset?.low)} showCurrency={false} />
      </span>
    </div>
  );
}
