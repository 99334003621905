import React from 'react';
import Icon from '@ant-design/icons';
import { Spin } from 'antd';
import cn from 'classnames';

import { LoadingIcon } from '../../assets/icons';

import styles from './CommonButton.module.scss';

type CommonButtonProps = {
  label?: string;
  icon?: React.ReactNode;
  containerClassName?: any;
  outerClassName?: any;
  type: 'primaryGreen' | 'primaryRed' | 'secondary' | 'tertiary';
  onClick: (event?: any) => void;
  disabled?: boolean;
  height?: number;
  width?: number;
  className?: any;
  loading?: boolean;
};
export default function CommonButton({
  label,
  icon,
  containerClassName,
  type,
  disabled,
  onClick,
  height,
  width,
  className,
  loading = false,
  outerClassName,
}: CommonButtonProps) {
  return (
    <div className={cn(containerClassName)}>
      <div
        className={cn(
          styles.outer,
          type === 'primaryGreen' && styles.outer_green,
          type === 'primaryRed' && styles.outer_red,
          type === 'secondary' && styles.outer_secondary,
          loading && styles.outer_loading,
          disabled && styles.outer_disabled,
          outerClassName,
        )}
        style={{ height: height ? `${height}px` : '44px', width: width ? `${width}px` : '100%' }}
      >
        <button
          onClick={onClick}
          disabled={disabled}
          className={cn(
            styles.button,
            type === 'primaryGreen' && styles.button_green,
            type === 'primaryRed' && styles.button_red,
            type === 'secondary' && styles.button_secondary,
            loading && styles.button_loading,
            className && className,
          )}
        >
          {loading && (
            <Spin indicator={<Icon component={LoadingIcon} className={styles.loadingIcon} spin />} spinning />
          )}
          {icon && <span className={styles.icon}>{icon}</span>}
          {label && <span>{label}</span>}
        </button>
      </div>
    </div>
  );
}
