import React, { useState } from 'react';
import { Drawer } from 'antd';

import styles from './BottomDrawer.module.scss';

type DriverProps = {
  children: React.ReactNode;
  visible: boolean;
  closeDriver: () => void;
  height?: number;
};
export default function BottomDrawer({ children, visible, closeDriver, height }: DriverProps) {
  const [, setPosition] = useState<number | null>(null);

  const onChangePosition = (y: number) => {
    setPosition((start) => {
      if (!start) {
        return y;
      } else if (y > start + 15) {
        closeDriver();
        return null;
      }
      return start;
    });
  };
  const handleTouchStart = () => {
    const handleTouchMove = (e: TouchEvent) => onChangePosition(e.touches[0].clientY);
    const handleTouchEnd = () => {
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
      setPosition(null);
    };

    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);
  };

  return (
    <Drawer
      className={styles.driver}
      open={visible}
      onClose={closeDriver}
      placement='bottom'
      closable={false}
      height={height}
    >
      <div className={styles.draggerArea} onTouchStart={handleTouchStart}>
        <span className={styles.bar}></span>
      </div>
      <div className={styles.menu}>{children}</div>
    </Drawer>
  );
}
