import React, { useState } from 'react';
import { Select } from 'antd';
import cn from 'classnames';

import { ArrowUpSecondaryIcon } from '../../assets/icons';

import styles from './CustomSelect.module.scss';

type CustomSelectProps = {
  options: any[];
  onSelect?: (v: any) => void;
  defaultValue?: string;
  width?: number;
  className?: any;
  value?: any;
};

export default function CustomSelect({ options, onSelect, defaultValue, width, className, value }: CustomSelectProps) {
  const [opened, setOpened] = useState(false);
  const onOpen = (open: boolean) => {
    setOpened(open);
  };

  return (
    <div className={styles.wrapper} style={{ flexGrow: width ? 0 : 1 }}>
      <Select
        onDropdownVisibleChange={onOpen}
        className={cn(styles.select, className)}
        popupClassName={styles.popup}
        options={options}
        value={value}
        onSelect={onSelect}
        defaultValue={defaultValue}
        style={{ width: `${width}px` }}
      />
      <ArrowUpSecondaryIcon className={cn(styles.icon, opened && styles.icon_opened)} />
    </div>
  );
}
