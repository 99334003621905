import React from 'react';
import { NumericFormat } from 'react-number-format';

import { useAppDispatch } from '../../../../store';
import { decrementBetValue, incrementBetValue, setBetValue } from '../../../../store/bet/slices';

import styles from './Wager.module.scss';

interface WagerProps {
  betValue: number;
}

export default function Wager({ betValue }: WagerProps) {
  const dispatch = useAppDispatch();

  const increment = () => {
    dispatch(incrementBetValue());
  };

  const decrement = () => {
    dispatch(decrementBetValue());
  };

  const handleChangeNumericFormat = (e: React.ChangeEvent<HTMLInputElement>) => {
    const [, value] = e.target.value.split(' ');
    const numberValue = parseFloat(value?.replace(/,/g, ''));
    if (typeof value === 'undefined') {
      dispatch(setBetValue(0));
    } else {
      dispatch(setBetValue(numberValue));
    }
  };

  return (
    <div className={styles.wager}>
      <p className={styles.label}>Wager</p>
      <div className={styles.display}>
        <button className={styles.button} onClick={decrement}>
          <div className={styles.button_line} />
        </button>
        <div className={styles.price}>
          <NumericFormat
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            allowNegative={false}
            prefix={'$ '}
            placeholder=''
            inputMode='numeric'
            value={betValue}
            onChange={handleChangeNumericFormat}
          />
        </div>
        <button className={styles.button} onClick={increment}>
          <div className={styles.button_line} />
          <div className={styles.button_line_vertical} />
        </button>
      </div>
    </div>
  );
}
