import React from 'react';
import { NumericFormat } from 'react-number-format';

import styles from './CloseBetSetting.module.scss';

interface CloseBetSettingProps {
  price: string;
  loss: string;
  placeholder?: string;
  error?: boolean | undefined;
  errorText?: string;
  onChangeClosePrice: (value: string) => void;
  onChangeCloseLoss: (value: string) => void;
  onClosePriceFocus?: () => void;
  onCloseLossFocus?: () => void;
}

export default function CloseBetSetting({
  price,
  loss,
  placeholder,
  error,
  errorText,
  onChangeClosePrice,
  onChangeCloseLoss,
  onClosePriceFocus,
  onCloseLossFocus,
}: CloseBetSettingProps) {
  return (
    <div className={styles.closeBet}>
      <p className={styles.label}>Close bet at price / loss</p>
      <div className={styles.blocks}>
        <div className={styles.block}>
          <NumericFormat
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={false}
            allowNegative={false}
            prefix={''}
            placeholder={placeholder}
            inputMode='numeric'
            value={price}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeClosePrice?.(e.target.value)}
            onFocus={onClosePriceFocus}
          />
        </div>

        <div className={styles.block}>
          <span>-$</span>
          <NumericFormat
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={false}
            allowNegative={false}
            prefix={''}
            placeholder={placeholder}
            inputMode='numeric'
            value={loss}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeCloseLoss?.(e.target.value)}
            onFocus={onCloseLossFocus}
          />
        </div>
      </div>
      {error && <div className={styles.block_error}>{errorText}</div>}
    </div>
  );
}
