import React, { useMemo } from 'react';

import FormattedAmount from '../../FormattedAmount/FormattedAmount';

import styles from './PNLCell.module.scss';

type PNLCellType = {
  value: number;
};

export default function PNLCell({ value }: PNLCellType) {
  const positive = useMemo(() => value > 0, [value]);
  const negative = useMemo(() => value < 0, [value]);
  const valueString = useMemo(() => Math.abs(value), [value]);

  if (!value && value !== 0) return null;
  return (
    <span className={positive ? styles.positive : negative ? styles.negative : styles.neutral}>
      {positive && '+'}
      {negative && '-'}
      <FormattedAmount amount={valueString} />
    </span>
  );
}
