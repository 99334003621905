import { createSlice } from '@reduxjs/toolkit';

import { UserSliceState } from './types';

const initialState: UserSliceState = {
  token: null,
  playerId: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setPlayerId: (state, action) => {
      state.playerId = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setToken, setPlayerId } = userSlice.actions;

export default userSlice.reducer;
