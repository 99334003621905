import React from 'react';

import CustomSelect from '../CustomSelect/CustomSelect';

import styles from './LeaderboardFilters.module.scss';

export default function LeaderboardFilters() {
  return (
    <div className={styles.container}>
      <CustomSelect defaultValue='all' options={[{ value: 'all', label: 'All instruments' }]} onSelect={() => {}} />
      <CustomSelect
        defaultValue='pnl'
        options={[
          { value: 'pnl', label: 'PNL' },
          { value: 'roi', label: 'ROI' },
        ]}
        onSelect={() => {}}
        width={80}
      />
      <CustomSelect
        defaultValue='day'
        width={80}
        options={[
          { value: 'day', label: 'Day' },
          { value: 'week', label: 'Week' },
          { value: 'month', label: 'Month' },
        ]}
        onSelect={() => {}}
      />
    </div>
  );
}
