import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';

import { setupAxiosInterceptors } from '../api/axiosInstance';
import { API } from '../api/uri';
import { useAppDispatch } from '../store';
import { setPlayerId, setToken } from '../store/user/slices';

const ApiService = () => {
  const [searchParams] = useSearchParams();
  const playerId = searchParams.get('playerId');
  const franchiseId = searchParams.get('franchiseId');
  const dispatch = useAppDispatch();

  const launchGame = async (player_id: string, franchise_id: string) => {
    try {
      const response = await axios({
        method: 'post',
        url: `${API.START_GAME}/${franchise_id}/players/${player_id}/launch`,
        data: {
          GameProvider: 'CasinoGames::Limeup',
          CasinoProviderId: 178,
          GameId: 'test-limeup',
          Source: 1,
          Locale: 'en_GB',
          Demo: false,
          AdditionalData: null,
        },
      });
      if (response.data?.token) {
        // console.log('token:', response.data.token);
        dispatch(setToken(response.data.token));
        dispatch(setPlayerId(Number(player_id)));
        setupAxiosInterceptors(response.data.token);
      }
    } catch (e) {
      console.log('Error get token');
      dispatch(setToken(null));
    }
  };

  useEffect(() => {
    console.log({ playerId, franchiseId });
    if (playerId && franchiseId) {
      launchGame(playerId, franchiseId);
    }
  }, [playerId, franchiseId]);

  return null;
};

export default ApiService;
