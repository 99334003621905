import React, { MutableRefObject } from 'react';

import { BookIcon, CalculatorIcon, WarningIcon } from '../../../../../assets/icons';

import styles from './Popup.module.scss';

interface PopupProps {
  popupRef: MutableRefObject<HTMLDivElement | null>;
  onOpenHowItWorksModal: () => void;
  onOpenRiskWarningModal: () => void;
  onOpenRoiCalculatorModal: () => void;
}

export default function Popup({
  popupRef,
  onOpenHowItWorksModal,
  onOpenRiskWarningModal,
  onOpenRoiCalculatorModal,
}: PopupProps) {
  return (
    <div className={styles.popup} ref={popupRef}>
      <button className={styles.popup_item} onClick={onOpenHowItWorksModal}>
        <BookIcon />
        How it works
      </button>
      <button className={styles.popup_item} onClick={onOpenRoiCalculatorModal}>
        <CalculatorIcon />
        ROI Calculator
      </button>
      <button className={styles.popup_item} onClick={onOpenRiskWarningModal}>
        <WarningIcon />
        Risk Warning
      </button>
    </div>
  );
}
