import React, { useCallback, useState } from 'react';
import { Modal, RadioChangeEvent } from 'antd';

import { useTypedSelector } from '../../hooks/useTypedSelector';
import useWindowSize from '../../hooks/useWindowSize';
import { useAppDispatch } from '../../store';
import { setShowRoiCalculator } from '../../store/ui/slices';
import { CommissionType, Prediction } from '../../types/entities';
import CommonButton from '../CommonButton/CommonButton';

import CalculatorTable from './CalculatorTable/CalculatorTable';
import CurrencyDropdown from './CurrencyDropdown/CurrencyDropdown';
import Dashboard from './Dashboard/Dashboard';
import useEntryPrice from './useEntryPrice';

import styles from './RoiCalculatorModal.module.scss';

const getModalStyles = (isTablet: boolean) => {
  return {
    content: {
      padding: isTablet ? 20 : 30,
      background: '#21242E',
      boxShadow: '0 1px 2px 0 #0D0E120D',
    },
    mask: {
      backdropFilter: 'blur(10px)',
    },
  };
};

export default function RoiCalculatorModal() {
  const dispatch = useAppDispatch();
  const { isTablet } = useWindowSize();

  const [wager, setWager] = useState<string>('1');
  const [priceWillGo, setPriceWillGo] = useState<string>(Prediction.UP);
  const [multiplier, setMultiplier] = useState<string>('1');
  const [customPriceMove, setCustomPriceMove] = useState<string>('');
  const [selected, setSelected] = useState<string>(CommissionType.PNL_FEE);
  const [currency, setCurrency] = useState<string>('BTC/USD');

  const { showRoiCalculator } = useTypedSelector((state) => state.ui);
  const { currentAsset } = useTypedSelector((state) => state.assets);

  const { entryPriceValue, setEntryPriceValue } = useEntryPrice();

  const handleCloseCalculator = useCallback(() => {
    dispatch(setShowRoiCalculator(false));
  }, [dispatch]);

  const handleChangeCurrency = useCallback((val: string) => {
    setCurrency(val);
  }, []);

  const handleChangeMultiplier = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMultiplier(value);
  };

  const handleChangeWager = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setWager(value);
  };

  const handleChangeCustomPriceMove = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCustomPriceMove(value);
  };

  const handleChangePriceWillGo = useCallback((value: string) => {
    setPriceWillGo(value);
  }, []);

  const handleChangeRadio = useCallback((e: RadioChangeEvent) => {
    setSelected(e.target.value);
  }, []);

  const handleResetFields = () => {
    setWager('1');
    setMultiplier('1');
    setCustomPriceMove('');
    setSelected(CommissionType.PNL_FEE);
    setPriceWillGo(Prediction.UP);
    setEntryPriceValue(Number(currentAsset?.lastPrice));
  };

  return (
    <Modal
      open={showRoiCalculator}
      onCancel={handleCloseCalculator}
      width={800}
      centered={true}
      forceRender={false}
      styles={getModalStyles(isTablet)}
      footer={[
        <div className={styles.footer}>
          <div className={styles.footer_text}>
            *Calculator outcomes are estimates and actual ROI/PNL can differ to what's shown based on market conditions.
          </div>
          <CommonButton
            type='primaryRed'
            onClick={handleCloseCalculator}
            label='Got It'
            containerClassName={styles.footer_button}
          />
        </div>,
      ]}
    >
      <>
        <div className={styles.header}>
          <div className={styles.header_top}>
            <div className={styles.header_top_title}>ROI Calculator</div>
            <div className={styles.header_top_select}>
              <CurrencyDropdown currency={currency} onChangeCurrency={handleChangeCurrency} />
            </div>
          </div>
          <div className={styles.header_text}>BTC has 2.5% house edge and a 5% fee on profitable bets.</div>
        </div>
        <div className={styles.main}>
          <Dashboard
            wager={wager}
            priceWillGo={priceWillGo}
            multiplier={multiplier}
            entryPrice={entryPriceValue}
            customPriceMove={customPriceMove}
            selected={selected}
            onChangeWager={handleChangeWager}
            onChangePriceWillGo={handleChangePriceWillGo}
            onChangeMultiplier={handleChangeMultiplier}
            onChangeCustomPriceMove={handleChangeCustomPriceMove}
            onChangeRadio={handleChangeRadio}
            onResetFields={handleResetFields}
          />
          <CalculatorTable
            wager={wager}
            priceWillGo={priceWillGo}
            multiplier={multiplier}
            entryPriceNumber={entryPriceValue}
            selected={selected}
            customPriceMove={customPriceMove}
          />
        </div>
      </>
    </Modal>
  );
}
