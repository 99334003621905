export const formatNumber = (PNL: number, isCurrency: boolean = false): string => {
  const baseOptions: Intl.NumberFormatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const formatOptions: Intl.NumberFormatOptions = isCurrency
    ? {
        ...baseOptions,
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'symbol',
      }
    : baseOptions;

  return new Intl.NumberFormat('en-US', formatOptions).format(PNL);
};
