import { createSlice } from '@reduxjs/toolkit';

import { STORAGE_KEY } from '../../constants/constatnts';

import { UISliceState } from './types';

const initialState: UISliceState = {
  menuDrawerVisible: false,
  betWidgetDrawerVisible: false,
  betInfoModalVisible: false,
  betSettingsModalVisible: false,
  showRoiCalculator: false,
  showHowItWorks: false,
  showRiskWarning: false,
  showRiskAfterClose: false,
  showAdvancedChart: localStorage.getItem(STORAGE_KEY.SHOW_ADVANCED_CHART)
    ? Boolean(Number(localStorage.getItem(STORAGE_KEY.SHOW_ADVANCED_CHART)))
    : false,
  showMyBetsOnChart: localStorage.getItem(STORAGE_KEY.SHOW_MY_BETS_ON_CHART)
    ? Boolean(Number(localStorage.getItem(STORAGE_KEY.SHOW_MY_BETS_ON_CHART)))
    : true,
  showPublicBetsOnChart: localStorage.getItem(STORAGE_KEY.SHOW_PUBLIC_BETS_ON_CHART)
    ? Boolean(Number(localStorage.getItem(STORAGE_KEY.SHOW_PUBLIC_BETS_ON_CHART)))
    : false,
};
const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setMenuDrawerVisible: (state, action) => {
      state.menuDrawerVisible = action.payload;
    },
    setBetWidgetDrawerVisible: (state, action) => {
      state.betWidgetDrawerVisible = action.payload;
    },
    setBetInfoModalVisible: (state, action) => {
      state.betInfoModalVisible = action.payload;
    },
    setBetSettingsModalVisible: (state, action) => {
      state.betSettingsModalVisible = action.payload;
      if (action.payload) {
        state.betInfoModalVisible = false;
      }
    },
    setShowHowItWorks: (state, action) => {
      state.showHowItWorks = action.payload;
    },
    setShowRiskWarning: (state, action) => {
      state.showRiskWarning = action.payload;
    },
    setShowRiskAfterClose: (state, action) => {
      state.showRiskAfterClose = action.payload;
    },
    setShowRoiCalculator: (state, action) => {
      state.showRoiCalculator = action.payload;
    },
    setShowMyBetsOnChart: (state, action) => {
      state.showMyBetsOnChart = action.payload;
      localStorage.setItem(STORAGE_KEY.SHOW_MY_BETS_ON_CHART, action.payload ? '1' : '0');
    },
    setShowPublicBetsOnChart: (state, action) => {
      state.showPublicBetsOnChart = action.payload;
      localStorage.setItem(STORAGE_KEY.SHOW_PUBLIC_BETS_ON_CHART, action.payload ? '1' : '0');
    },
    setShowAdvancedChart: (state, action) => {
      state.showAdvancedChart = action.payload;
      localStorage.setItem(STORAGE_KEY.SHOW_ADVANCED_CHART, action.payload ? '1' : '0');
    },
  },
});

export const {
  setMenuDrawerVisible,
  setBetWidgetDrawerVisible,
  setBetSettingsModalVisible,
  setBetInfoModalVisible,
  setShowHowItWorks,
  setShowRiskWarning,
  setShowRiskAfterClose,
  setShowRoiCalculator,
  setShowPublicBetsOnChart,
  setShowMyBetsOnChart,
  setShowAdvancedChart,
} = uiSlice.actions;
export const uiReducer = uiSlice.reducer;
