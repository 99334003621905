import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import CommonButton from '../CommonButton/CommonButton';

import styles from './CoinMenuItem.module.scss';

type CoinMenuItemProps = {
  icon_id?: string;
  icon?: any;
  max_multiplier: number;
  name: string;
  title: string;
  prices?: number[];
};

export default function CoinMenuItem({ max_multiplier, name, title, icon_id, icon }: CoinMenuItemProps) {
  const [delta, setDelta] = useState(0);

  const onTradeClick = useCallback(() => {}, []);

  return (
    <Link to={`/${name}/active-bets`}>
      <div className={styles.wrapper}>
        <div className={styles.inner}>
          <img alt='' src={icon} className={styles.icon} />
          <div className={styles.nameBlock}>
            <span className={styles.title}>{title}</span>
            <span className={styles.name}>{name}</span>
          </div>
          <div className={styles.valueBlock}>
            <span className={styles.value}>31,234.541</span>
            <span className={styles.label}>Price</span>
          </div>
          <div className={styles.valueBlock}>
            <span className={styles.value}>{`${max_multiplier}x`}</span>
            <span className={styles.label}>Multiplier</span>
          </div>
          <div className={styles.valueBlock}>
            <span className={styles.value}>$59.3M</span>
            <span className={styles.label}>24h Vol</span>
          </div>
          <div className={styles.valueBlock}>
            <span className={styles.value}>{`${delta}`}</span>
            <span className={styles.label}>Change</span>
          </div>
          <div>{/*  coin chart */}</div>
          <CommonButton type='primaryRed' onClick={onTradeClick} label='Trade' width={72} height={38} />
        </div>
      </div>
    </Link>
  );
}
