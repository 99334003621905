import { DEFAULT_INTERVAL } from '../../constants/constatnts';

import { formatDateString, makeApiRequest } from './helpers.js';
import { subscribeOnStream, unsubscribeFromStream } from './streaming.js';
import axios from 'axios';
import { store } from '../../store';

axios.defaults.baseURL = process.env.REACT_APP_API;

const lastBarsCache = new Map();

const numOfDecimal = (num = 0) => {
  const number = String(num);
  const index = number.lastIndexOf('.');
  return number.slice(index + 1).length;
};

const resolutions = {
  '1T': 'ONE_SEC',
  '5S': 'FIVE_SEC',
  '15S': 'FIFTEEN_SEC',
  '30S': 'THIRTY_SEC',
  1: 'ONE_MIN',
  5: 'FIVE_MIN',
};

const configurationData = {
  supported_resolutions: ['1T', '5S', '15S', '30S', '1', '5'],
  exchanges: [],
};

export default {
  onReady: (callback) => {
    setTimeout(() => {
      if (callback) callback(configurationData);
    });
  },

  searchSymbols: async (userInput, exchange, symbolType, onResultReadyCallback) => {
    console.log('[searchSymbols]: Method call', userInput);
    const symbols = store.getState().assets.assets;

    console.log('symbols', symbols);

    const newSymbols = symbols
      .filter((symbol) => {
        return symbol.s.toLowerCase().includes(userInput.toLowerCase());
      })
      .map((symbol) => ({
        description: symbol.s,
        exchange: symbol.s,
        full_name: symbol.s,
        symbol: symbol.s,
        type: symbol.type,
      }));

    onResultReadyCallback(newSymbols);
  },

  resolveSymbol: async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
    const symbols = store.getState().assets.assets;
    const symbol = symbols?.find((symbol) => symbol?.symbol === symbolName);
    if (!symbol) return;
    const { symbol: s, lastPrice: lp, high: h, low: l } = symbol;
    const lastPrice = numOfDecimal(lp);
    const high = numOfDecimal(h);
    const low = numOfDecimal(l);
    const pricescale = Math.max(lastPrice, high, low);

    const symbolInfo = {
      ticker: s,
      name: s,
      description: s,
      type: symbol.type.toLowerCase(),
      session: '24x7',
      timezone: 'Etc/UTC',
      exchange: 'USD', // ???
      minmov: 1,
      pricescale: Math.pow(10, Math.max(2, pricescale)),
      has_intraday: true,
      visible_plots_set: 'ohlc',
      has_weekly_and_monthly: false,
      supported_resolutions: configurationData.supported_resolutions,
      volume_precision: 2, // TODO: get from server (from asset settings in admin)
      data_status: 'streaming',
      has_seconds: true,
      has_ticks: true,
    };

    setTimeout(() => onSymbolResolvedCallback(symbolInfo), 0);
  },

  getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
    const { from, to, firstDataRequest } = periodParams;
    // console.log({ from, to, firstDataRequest });
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const urlParameters = {
      symbolCode: symbolInfo.full_name,
      from: formatDateString(from),
      to: formatDateString(to),
      resolution: resolutions[resolution] || resolutions[DEFAULT_INTERVAL],
      timeZone: timezone,
      // fromCache: true,
    };

    const query = Object.keys(urlParameters)
      .map((name) => `${name}=${encodeURIComponent(urlParameters[name])}`)
      .join('&');
    try {
      const data = await makeApiRequest(`bars/${symbolInfo.type}?${query}`);
      if (!data) return;
      if ((data.Response && data.Response === 'Error') || data.length === 0) {
        // "noData" should be set if there is no data in the requested period.
        onHistoryCallback([], {
          noData: true,
        });
        return;
      }

      let bars = [];

      data
        // .sort((a, b) => a.t - b.t)
        .forEach((bar) => {
          if (bar.t >= from * 1000 && bar.t < to * 1000) {
            bars = [
              ...bars,
              {
                time: bar.t,
                low: bar.l,
                high: bar.h,
                open: bar.o,
                close: bar.c,
              },
            ];
          }
        });
      if (firstDataRequest) {
        lastBarsCache.set(symbolInfo.full_name, {
          ...bars[bars.length - 1],
        });
      }
      onHistoryCallback(bars, {
        noData: false,
      });
    } catch (error) {
      // console.log('Get Bars error', error);
      onHistoryCallback([], {
        noData: true,
      });
      onErrorCallback(error);
    }
  },

  subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {
    subscribeOnStream(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      onResetCacheNeededCallback,
      lastBarsCache.get(symbolInfo.full_name),
    );
  },

  unsubscribeBars: (subscriberUID) => {
    unsubscribeFromStream(subscriberUID);
  },
};
