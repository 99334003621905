import React from 'react';
import cn from 'classnames';

import { BetStatus } from '../../../types/entities';

import styles from './BetStatusCell.module.scss';

type BetStatusProps = {
  status: BetStatus;
  className?: any;
};

export default function BetStatusCell({ status, className }: BetStatusProps) {
  if (status === BetStatus.CLOSED) {
    return <span className={cn(styles.status_cashedOut, className)}>Closed</span>;
  }
  if (status === BetStatus.Active) {
    return <span className={cn(styles.status_active, className)}>Active</span>;
  }
  return null;
}
