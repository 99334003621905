import React from 'react';

import EmptyStateImg from '../../assets/images/empty-state.png';

import styles from './EmptyState.module.scss';

type EmptyStateProps = {
  header?: string;
  description?: string[];
  smallImg?: boolean;
};

export default function EmptyState({ header, description, smallImg = false }: EmptyStateProps) {
  return (
    <div className={styles.emptyWrapper}>
      <img src={EmptyStateImg} style={{ width: smallImg ? 36 : 61, height: smallImg ? 40 : 68 }} alt='' />
      {header && <span className={styles.header}>{header}</span>}
      <div>
        {description &&
          description.map((item, index) => (
            <span key={index} className={styles.text}>
              {item}
            </span>
          ))}
      </div>
    </div>
  );
}
