import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal } from 'antd';

import { getAutoBetErrorForClose, getAutoBetErrorForTake } from '../../helpers/autoBetErrors';
import {
  calculateBustPrice,
  calculateCloseLoss,
  calculateClosePrice,
  calculateTakePrice,
  calculateTakeProfit,
} from '../../helpers/autoBetsFormulas';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import useWindowSize from '../../hooks/useWindowSize';
import { useAppDispatch } from '../../store';
import { editActiveBet } from '../../store/betList/asyncThunks/slice';
import { setBetSettingsModalVisible } from '../../store/ui/slices';
import { Prediction } from '../../types/entities';
import CloseBetSetting from '../CloseBetSetting/CloseBetSetting';
import CommonButton from '../CommonButton/CommonButton';
import TakeProfitSetting from '../TakeProfit/TakeProfitSetting';

import styles from './ActiveBetSettingsModal.module.scss';

export default function ActiveBetSettingsModal() {
  const { isMobile } = useWindowSize();
  const { selectedBet } = useTypedSelector((state) => state.betList);
  const { betSettingsModalVisible } = useTypedSelector((state) => state.ui);
  const dispatch = useAppDispatch();

  const entryPrice = selectedBet?.openPrice || 0;

  const [isSubmitPressed, setIsSubmitPressed] = useState<boolean>(false);

  const [takePrice, setTakePrice] = useState<string>('');
  const [takeProfit, setTakeProfit] = useState<string>('');
  const [closePrice, setClosePrice] = useState<string>('');
  const [stopLoss, setStopLoss] = useState<string>('');

  const isPriceUp = selectedBet?.prediction === Prediction.UP;

  const onConfirm = useCallback(async () => {
    setIsSubmitPressed(true);
    const isForbidSubmit = (!takePrice && !takeProfit) || (!closePrice && !stopLoss);

    if (isForbidSubmit) {
      return;
    }
    const requestData = {
      betId: selectedBet!.id,
      body: {
        status: selectedBet?.status,
        isPublic: selectedBet?.isPublic,
        takeProfitPrice: parseFloat(takePrice.replace(/,/g, '')) || selectedBet?.takeProfitPrice,
        stopLossPrice: parseFloat(closePrice.replace(/,/g, '')) || selectedBet?.stopLossPrice,
      },
    };
    await dispatch(editActiveBet(requestData));
    dispatch(setBetSettingsModalVisible(false));
    setIsSubmitPressed(false);
  }, [selectedBet, takeProfit, takePrice, stopLoss, dispatch, closePrice]);

  const onClose = useCallback(() => {
    dispatch(setBetSettingsModalVisible(false));
  }, [dispatch]);

  const { height, width } = useWindowSize();

  const top = useMemo(() => {
    if (selectedBet) {
      const el = document.getElementById(`${selectedBet.id}bet`);
      if (el) {
        const rect = el.getBoundingClientRect?.();
        if (rect?.bottom - 360 > 0) {
          return rect?.bottom - height / 2 - 170;
        }
        if (rect?.top + 80 < height / 2) {
          return rect?.bottom - height / 2 + 160;
        }
        return 0;
      }
    }
    return 0;
  }, [selectedBet, height]);

  const handleChangeTakePrice = useCallback(
    (takePriceValue: string) => {
      setTakePrice(takePriceValue);
      const calculatedTakeProfit = calculateTakeProfit(
        entryPrice,
        takePriceValue,
        isPriceUp,
        selectedBet!.amount,
        selectedBet!.multiplier,
      );
      setTakeProfit(calculatedTakeProfit);
    },
    [entryPrice, isPriceUp, selectedBet],
  );

  const handleChangeTakeProfit = (takeProfitValue: string) => {
    setTakeProfit(takeProfitValue);
    const calculatedTakePrice = calculateTakePrice(
      entryPrice,
      takeProfitValue,
      isPriceUp,
      selectedBet!.amount,
      selectedBet!.multiplier,
    );
    setTakePrice(calculatedTakePrice);
  };

  const handleChangeClosePrice = useCallback(
    (closePriceValue: string) => {
      setClosePrice(closePriceValue);
      const calculatedCloseLoss = calculateCloseLoss(
        entryPrice,
        closePriceValue,
        isPriceUp,
        selectedBet!.amount,
        selectedBet!.multiplier,
      );
      setStopLoss(calculatedCloseLoss);
    },
    [entryPrice, isPriceUp, selectedBet],
  );

  const handleChangeStopLoss = (stopLossValue: string) => {
    setStopLoss(stopLossValue);
    const calculatedClosePrice = calculateClosePrice(
      entryPrice,
      stopLossValue,
      isPriceUp,
      selectedBet!.amount,
      selectedBet!.multiplier,
    );
    setClosePrice(calculatedClosePrice);
  };

  const calculatedBustPrice = calculateBustPrice(entryPrice, selectedBet?.multiplier as number, isPriceUp);

  const { isErrorTakeCurrentPrice, errorTakeCurrentPriceText } = getAutoBetErrorForTake(
    entryPrice,
    selectedBet?.prediction || Prediction.UP,
    takePrice.toString(),
  );

  const { isErrorCloseCurrentPrice, isErrorCloseBustPrice, isErrorCloseLoss, errorCloseText } = getAutoBetErrorForClose(
    entryPrice,
    selectedBet?.prediction || Prediction.UP,
    closePrice.toString(),
    stopLoss.toString(),
    calculatedBustPrice,
  );

  useEffect(() => {
    const setSelectedValues = () => {
      if (selectedBet?.takeProfitPrice && selectedBet?.stopLossPrice) {
        handleChangeTakePrice(selectedBet?.takeProfitPrice.toString());
        handleChangeClosePrice(selectedBet.stopLossPrice.toString());
      }
    };
    setSelectedValues();
    return () => {
      setSelectedValues();
    };
  }, [selectedBet?.takeProfitPrice, selectedBet?.stopLossPrice, betSettingsModalVisible]);

  useEffect(() => {
    return () => {
      setTakePrice('');
      setTakeProfit('');
      setClosePrice('');
      setStopLoss('');
      setIsSubmitPressed(false);
    };
  }, [betSettingsModalVisible]);

  const renderError = (condition: boolean, message: string) =>
    condition && <div className={styles.error}>{message}</div>;

  return (
    <Modal
      style={isMobile ? {} : { top: top, left: width / 2 - 200 }}
      open={betSettingsModalVisible}
      onCancel={onClose}
      onOk={onConfirm}
      width={isMobile ? 350 : 288}
      centered={true}
      forceRender={false}
      footer={[
        <>
          <CommonButton
            type='primaryRed'
            onClick={onConfirm}
            label='Confirm'
            disabled={isErrorTakeCurrentPrice || isErrorCloseCurrentPrice || isErrorCloseBustPrice || isErrorCloseLoss}
          />
          {renderError(!takePrice && !takeProfit && isSubmitPressed, 'Take profit at price is required')}
          {renderError(!closePrice && !stopLoss && isSubmitPressed, 'Close bet at price is required')}
        </>,
      ]}
    >
      <div className={styles.header}>Bet Settings</div>
      <div className={styles.content}>
        <TakeProfitSetting
          price={takePrice}
          profit={takeProfit}
          onChangeTakePrice={handleChangeTakePrice}
          onChangeTakeProfit={handleChangeTakeProfit}
          error={isErrorTakeCurrentPrice}
          errorText={errorTakeCurrentPriceText}
        />
        <CloseBetSetting
          price={closePrice}
          loss={stopLoss}
          onChangeClosePrice={handleChangeClosePrice}
          onChangeCloseLoss={handleChangeStopLoss}
          error={isErrorCloseCurrentPrice || isErrorCloseBustPrice || isErrorCloseLoss}
          errorText={errorCloseText}
        />
      </div>
    </Modal>
  );
}
