import { CommissionType, Prediction } from '../../../types/entities';

import { DataType } from './types';

export const calculateROI = (
  priceMoveNumber: number,
  entryPriceNumber: number,
  multiplierNumber: number,
  wagerNumber: number,
  priceWillGo: string,
  selected: string,
) => {
  const PNL_FEE_PERCENTAGE = 10.001;
  const PNL_FEE = PNL_FEE_PERCENTAGE / 100;

  const FLAT_FEE_PERCENTAGE = 0;
  const FLAT_FEE = FLAT_FEE_PERCENTAGE / 100;

  const exitPrice = entryPriceNumber * (1 + priceMoveNumber / 100);

  const getRoiPnlFeeUp = () => {
    if (ROI_PNL_FEE_UP > 0) {
      return ROI_PNL_FEE_UP - PNL_FEE * priceMoveNumber;
    }
    return ROI_PNL_FEE_UP;
  };
  const getRoiPnlFeeDown = () => {
    if (ROI_PNL_FEE_DOWN < -100) {
      return -100;
    }
    if (ROI_PNL_FEE_DOWN > 0) {
      return ROI_PNL_FEE_DOWN + PNL_FEE * priceMoveNumber;
    }
    return ROI_PNL_FEE_DOWN;
  };

  // PNL fee
  const ROI_PNL_FEE_UP = ((exitPrice - entryPriceNumber) / entryPriceNumber) * multiplierNumber * 100;
  const ROI_PNL_FEE_DOWN = ((entryPriceNumber - exitPrice) / entryPriceNumber) * multiplierNumber * 100;
  const ROI_PNL_FEE = priceWillGo === Prediction.UP ? getRoiPnlFeeUp() : getRoiPnlFeeDown();

  const PNL_PNL_FEE_UP =
    (wagerNumber * (exitPrice - entryPriceNumber) * multiplierNumber) / entryPriceNumber -
    ((wagerNumber * (exitPrice - entryPriceNumber) * multiplierNumber) / entryPriceNumber) *
      (ROI_PNL_FEE < 0 ? 0 : PNL_FEE);

  const PNL_PNL_FEE_DOWN =
    (wagerNumber * (entryPriceNumber - exitPrice) * multiplierNumber) / entryPriceNumber -
    ((wagerNumber * (entryPriceNumber - exitPrice) * multiplierNumber) / entryPriceNumber) *
      (ROI_PNL_FEE < 0 ? 0 : PNL_FEE);

  const PNL_PNL_FEE =
    priceWillGo === Prediction.UP ? PNL_PNL_FEE_UP : PNL_PNL_FEE_DOWN < -wagerNumber ? -wagerNumber : PNL_PNL_FEE_DOWN;

  // FLAT fee
  const ROI_FLAT_FEE_UP = ((exitPrice - entryPriceNumber) / entryPriceNumber) * multiplierNumber * 100;
  const ROI_FLAT_FEE_DOWN = ((entryPriceNumber - exitPrice) / entryPriceNumber) * multiplierNumber * 100;

  const ROI_FLAT_FEE =
    priceWillGo === Prediction.UP ? ROI_FLAT_FEE_UP : ROI_FLAT_FEE_DOWN < -100 ? -100 : ROI_FLAT_FEE_DOWN;

  const PNL_FLAT_FEE_UP =
    (wagerNumber * (exitPrice - entryPriceNumber) * multiplierNumber) / entryPriceNumber -
    ((wagerNumber * (exitPrice - entryPriceNumber) * multiplierNumber) / entryPriceNumber) * FLAT_FEE;

  const PNL_FLAT_FEE_DOWN =
    (wagerNumber * (entryPriceNumber - exitPrice) * multiplierNumber) / entryPriceNumber -
    ((wagerNumber * (entryPriceNumber - exitPrice) * multiplierNumber) / entryPriceNumber) * FLAT_FEE;

  const PNL_FLAT_FEE =
    priceWillGo === Prediction.UP
      ? PNL_FLAT_FEE_UP
      : PNL_FLAT_FEE_DOWN < -wagerNumber
        ? -wagerNumber
        : PNL_FLAT_FEE_DOWN;

  const ROI = selected === CommissionType.PNL_FEE ? ROI_PNL_FEE : ROI_FLAT_FEE;
  const PNL = selected === CommissionType.PNL_FEE ? PNL_PNL_FEE : PNL_FLAT_FEE;

  return { exitPrice, ROI, PNL };
};

export const getColor = (record: DataType, multiplierNumber: number, wagerNumber: number) => {
  const ROIValue = parseFloat(record.ROI.toString().replace(/,/g, ''));
  const PNLValue = parseFloat(record.PNL.toString().replace('$', ''));
  if (PNLValue === 0 || multiplierNumber === 0 || wagerNumber === 0) {
    return '#fff';
  }
  if (ROIValue < 0) {
    return '#E34D44';
  }
  if (ROIValue > 0) {
    return '#4CAF50';
  }
  return '#fff';
};
