import React from 'react';
import Icon from '@ant-design/icons';

import { FirstPlaceIcon, SecondPlaceIcon, ThirdPlaceIcon } from '../../../assets/icons';

import styles from './RankCell.module.scss';

type RankCellProps = {
  rank: number;
};

export default function RankCell({ rank }: RankCellProps) {
  if (rank === 1) return <Icon component={FirstPlaceIcon} className={styles.icon} />;
  if (rank === 2) return <Icon component={SecondPlaceIcon} className={styles.icon} />;
  if (rank === 3) return <Icon component={ThirdPlaceIcon} className={styles.icon} />;
  return <span className={styles.wrapper}>{rank}</span>;
}
