import React, { useCallback } from 'react';
import Icon from '@ant-design/icons';
import { Tooltip } from 'antd';

import { InfoIcon } from '../../../assets/icons';
import useWindowSize from '../../../hooks/useWindowSize';
import { useAppDispatch } from '../../../store';
import { setSelectedBet } from '../../../store/betList/slices';
import { setBetInfoModalVisible } from '../../../store/ui/slices';
import { PublicBet } from '../../../types/entities';

import styles from './BetInfoCell.module.scss';

type BetInfoCellProps = {
  bet: PublicBet;
};

export default function BetInfoCell({ bet }: BetInfoCellProps) {
  const { isMobile } = useWindowSize();
  const dispatch = useAppDispatch();

  const onBetInfo = useCallback(() => {
    dispatch(setSelectedBet(bet));
    dispatch(setBetInfoModalVisible(true));
  }, [dispatch, bet]);

  return isMobile ? (
    <span className={styles.wrapper} onClick={onBetInfo} id={`${bet?.id}bet`}>
      <Icon component={InfoIcon} className={styles.infoIcon} />
    </span>
  ) : (
    <Tooltip title={'Bet Details'}>
      <span className={styles.wrapper} onClick={onBetInfo} id={`${bet?.id}bet`}>
        <Icon component={InfoIcon} className={styles.infoIcon} />
      </span>
    </Tooltip>
  );
}
