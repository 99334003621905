import { useDispatch } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import { assetsReducer } from './assets/slices';
import { betReducer } from './bet/slices';
import { betListReducer } from './betList/slices';
import { uiReducer } from './ui/slices';
import userReducer from './user/slices';

export const store = configureStore({
  reducer: {
    user: userReducer,
    bet: betReducer,
    ui: uiReducer,
    betList: betListReducer,
    assets: assetsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
