import React, { useCallback, useState } from 'react';
import cn from 'classnames';

import { useTypedSelector } from '../../hooks/useTypedSelector';
import BetWidget from '../../pages/Main/BetWidget/BetWidget';
import { useAppDispatch } from '../../store';
import { setBetWidgetDrawerVisible } from '../../store/ui/slices';

import styles from './BetWidgetDriver.module.scss';

export default function BetWidgetDrawer() {
  const { betWidgetDrawerVisible } = useTypedSelector((state) => state.ui);
  const [, setPosition] = useState<number | null>(null);
  const dispatch = useAppDispatch();

  const closeBetDriver = useCallback(() => {
    dispatch(setBetWidgetDrawerVisible(false));
  }, [dispatch]);

  const onChangePosition = (y: number) => {
    setPosition((start) => {
      if (!start) {
        return y;
      } else if (y < start - 15) {
        dispatch(setBetWidgetDrawerVisible(true));
        return null;
      } else if (y > start + 15) {
        dispatch(setBetWidgetDrawerVisible(false));
        return null;
      }
      return start;
    });
  };

  const handleTouchStart = () => {
    const handleTouchMove = (e: TouchEvent) => onChangePosition(e.touches[0].clientY);
    const handleTouchEnd = () => {
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
      setPosition(null);
    };

    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);
  };

  return (
    <>
      <div className={cn(styles.backdrop, betWidgetDrawerVisible && styles.backdrop_opened)} onClick={closeBetDriver} />
      <div className={styles.betDriver}>
        <div className={styles.draggerArea} onTouchStart={handleTouchStart}>
          <span className={styles.bar}></span>
        </div>
        <div className={styles.widgetWrapper}>
          <BetWidget widgetOpened={betWidgetDrawerVisible} />
        </div>
      </div>
    </>
  );
}
