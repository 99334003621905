import React, { useCallback, useEffect, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Checkbox, Modal, Steps } from 'antd';
import cn from 'classnames';

import { STORAGE_KEY } from '../../constants/constatnts';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import useWindowSize from '../../hooks/useWindowSize';
import { useAppDispatch } from '../../store';
import { setShowHowItWorks, setShowRiskWarning } from '../../store/ui/slices';
import CommonButton from '../CommonButton/CommonButton';

import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import Step3 from './Step3/Step3';
import Step4 from './Step4/Step4';

import '../../index.css';
import styles from './HowItWorksModal.module.scss';

const modalStyles = {
  content: {
    padding: '0 0 24px 0',
    background: '#21242E',
  },
  mask: {
    backdropFilter: 'blur(10px)',
  },
  modalClose: {
    top: 100,
  },
};

export default function HowItWorksModal() {
  const { width, isMobile } = useWindowSize();
  const notOpenRiskWarning = localStorage.getItem(STORAGE_KEY.HOW_IT_WORK_OPEN) === 'true';
  const [checked, setChecked] = useState(notOpenRiskWarning);
  const [step, setStep] = useState(0);
  const { showHowItWorks, showRiskAfterClose } = useTypedSelector((state) => state.ui);
  const dispatch = useAppDispatch();

  const setNotOpenModal = (e: any) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      localStorage.setItem(STORAGE_KEY.HOW_IT_WORK_OPEN, 'true');
    } else {
      localStorage.removeItem(STORAGE_KEY.HOW_IT_WORK_OPEN);
    }
  };

  const onNext = useCallback(() => {
    setStep((n) => n + 1);
  }, [setStep]);

  const onPrev = useCallback(() => {
    setStep((n) => n - 1);
  }, [setStep]);

  const handleClose = useCallback(() => {
    dispatch(setShowHowItWorks(false));
    if (showRiskAfterClose) {
      setTimeout(() => dispatch(setShowRiskWarning(true), 1000));
    }
  }, [dispatch, showRiskAfterClose]);

  useEffect(() => {
    if (!showHowItWorks) {
      setStep(0);
    }
  }, [showHowItWorks]);

  return (
    <Modal
      open={showHowItWorks}
      onCancel={handleClose}
      width={isMobile ? width - 32 : 900}
      centered={true}
      forceRender={false}
      destroyOnClose
      styles={modalStyles}
      footer={[
        <div className={styles.footer} key='1'>
          <Checkbox
            onChange={setNotOpenModal}
            checked={checked}
            className={checked ? cn(styles.checkbox, styles.checked) : styles.checkbox}
          >
            Don’t show again
          </Checkbox>
          <div className={styles.buttonsContainer}>
            {step > 0 && (
              <CommonButton type='secondary' label='Back' onClick={onPrev} containerClassName={styles.footer_button} />
            )}
            {step <= 3 && (
              <CommonButton
                type='primaryRed'
                label={step === 3 ? 'Got It' : 'Next'}
                onClick={step === 3 ? handleClose : onNext}
                containerClassName={styles.footer_button}
              />
            )}
          </div>
        </div>,
      ]}
    >
      <div className={styles.content}>
        <div className={styles.header}>
          <h2 className={styles.header_title}>How it works</h2>
        </div>
        <div className={styles.main}>
          <Steps
            current={step}
            items={[{}, {}, {}, {}]}
            className={styles.steps}
            direction='horizontal'
            responsive={false}
          />
          <TransitionGroup>
            <CSSTransition
              key={step}
              timeout={700}
              classNames={{
                enter: styles.fadeEnter,
                enterActive: styles.fadeEnterActive,
                exit: styles.fadeExit,
                exitActive: styles.fadeExitActive,
              }}
            >
              <div>
                {step === 0 && <Step1 />}
                {step === 1 && <Step2 />}
                {step === 2 && <Step3 />}
                {step === 3 && <Step4 />}
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </Modal>
  );
}
