import React from 'react';
import Icon from '@ant-design/icons';
import cn from 'classnames';

import { ArrowDownIcon, ArrowUpIcon } from '../../../assets/icons';
import USFlag from '../../../assets/images/US.png';

import styles from './BetPairCell.module.scss';

type BetPairCellProps = {
  coin: string;
  image?: any;
  imageUri?: string;
  direction: 'UP' | 'DOWN';
};

export default function BetPairCell({ coin, direction, image }: BetPairCellProps) {
  return (
    <div className={styles.container}>
      <div className={styles.imageWrapper}>
        <img src={image} alt='' className={styles.coinImage} />
        <img alt='' src={USFlag} className={styles.flagImage} />
      </div>
      <span className={styles.coinName}>{coin}</span>
      {/*<span className={styles.currencyName}>/USD</span>*/}
      {direction === 'UP' && <Icon component={ArrowUpIcon} className={cn(styles.arrowIcon, styles.up)} />}
      {direction === 'DOWN' && <Icon component={ArrowDownIcon} className={cn(styles.arrowIcon, styles.down)} />}
    </div>
  );
}
