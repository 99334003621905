import React from 'react';
import { Select } from 'antd';

import BTC from '../../../assets/images/btc.png';

import styles from './CurrencyDropdown.module.scss';

interface CurrencyDropdownProps {
  currency: string;
  onChangeCurrency: (value: string) => void;
}

export default function CurrencyDropdown({ currency, onChangeCurrency }: CurrencyDropdownProps) {
  return (
    <Select
      dropdownStyle={{ background: '#35394A' }}
      defaultValue='BTC/USD'
      style={{ width: '100%', height: 38 }}
      onChange={onChangeCurrency}
      className={styles.customSelect}
      value={currency}
      options={[
        {
          value: 'BTC/USD',
          label: (
            <div className={styles.item}>
              <img src={BTC} alt='' className={styles.coinImage} />
              <div>BTC/USD</div>
            </div>
          ),
        },
      ]}
    />
  );
}
