import { createSlice } from '@reduxjs/toolkit';

import { getActiveBets, getClosedBets, getMoreClosedBets, getPublicBets } from './asyncThunks/slice';
import { BetListState } from './types';

const initialState: BetListState = {
  selectedBet: null,
  betOnChart: null,
  loading: false,
  activeBets: [],
  closedBets: [],
  publicBets: [],
  error: '',
  closedBetsPageParams: {
    number: 0,
    totalPages: 0,
    last: true,
  },
};

const betListSlice = createSlice({
  name: 'betList',
  initialState,
  reducers: {
    setSelectedBet: (state, action) => {
      state.selectedBet = action.payload;
    },
    setBetOnChart: (state, action) => {
      state.betOnChart = action.payload;
    },
    setActiveBets: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.activeBets = action.payload;
        if (state.selectedBet) {
          const newBetState = action.payload.find((i) => i.id === state.selectedBet?.id);
          if (newBetState) {
            state.selectedBet = newBetState;
          }
        }
      }
    },
    setNewClosedBet: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.closedBets = [...action.payload, ...state.closedBets];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getActiveBets.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getActiveBets.fulfilled, (state, action) => {
      state.loading = false;
      state.activeBets = action.payload.content;
    });
    builder.addCase(getActiveBets.rejected, (state) => {
      state.loading = false;
      state.activeBets = [];
      state.error = 'Error';
    });

    builder.addCase(getClosedBets.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getClosedBets.fulfilled, (state, action) => {
      state.loading = false;
      state.closedBets = action.payload?.content;
      const { totalPages, last, number } = action.payload;
      state.closedBetsPageParams = { totalPages, number, last };
    });
    builder.addCase(getClosedBets.rejected, (state) => {
      state.loading = false;
      state.closedBets = [];
      state.error = 'Error';
    });

    builder.addCase(getMoreClosedBets.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMoreClosedBets.fulfilled, (state, action) => {
      state.loading = false;
      if (Array.isArray(action.payload.content)) {
        state.closedBets = [...state.closedBets, ...action.payload?.content];
      }
      const { totalPages, last, number } = action.payload;
      state.closedBetsPageParams = { totalPages, number, last };
    });
    builder.addCase(getMoreClosedBets.rejected, (state) => {
      state.loading = false;
      state.closedBets = [];
      state.error = 'Error';
    });

    builder.addCase(getPublicBets.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPublicBets.fulfilled, (state, action) => {
      state.loading = false;
      state.publicBets = action.payload.content;
    });
    builder.addCase(getPublicBets.rejected, (state) => {
      state.loading = false;
      state.publicBets = [];
      state.error = 'Error';
    });
  },
});

export const { setSelectedBet, setBetOnChart, setActiveBets, setNewClosedBet } = betListSlice.actions;
export const betListReducer = betListSlice.reducer;
