import React, { useEffect, useState } from 'react';

import CloseBetSetting from '../../../../components/CloseBetSetting/CloseBetSetting';
import TakeProfitSetting from '../../../../components/TakeProfit/TakeProfitSetting';
import {
  calculateCloseLoss,
  calculateClosePrice,
  calculateTakePrice,
  calculateTakeProfit,
} from '../../../../helpers/autoBetsFormulas';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { useAppDispatch } from '../../../../store';
import {
  changeCloseLossAction,
  changeClosePriceAction,
  changeTakePriceAction,
  changeTakeProfitAction,
} from '../../../../store/bet/slices';
import { Prediction } from '../../../../types/entities';

interface AutoBetProps {
  priceWillGo: string;
  takePrice: string;
  takeProfit: string;
  closePrice: string;
  closeLoss: string;
  isErrorTakeCurrentPrice: boolean;
  errorTakeCurrentPriceText: string;
  isErrorCloseLoss: boolean;
  isErrorCloseCurrentPrice: boolean;
  isErrorCloseBustPrice: boolean;
  errorCloseText: string;
  entryPrice: number;
}

export default function AutoBet({
  priceWillGo,
  takePrice,
  takeProfit,
  closePrice,
  closeLoss,
  isErrorTakeCurrentPrice,
  errorTakeCurrentPriceText,
  isErrorCloseLoss,
  isErrorCloseCurrentPrice,
  isErrorCloseBustPrice,
  errorCloseText,
  entryPrice,
}: AutoBetProps) {
  const dispatch = useAppDispatch();

  const { multiplierValue, betValue } = useTypedSelector((state) => state.bet);

  const [isTakePriceFocus, setIsTakePriceFocus] = useState<boolean>(false);
  const [isTakeProfitFocus, setIsTakeProfitFocus] = useState<boolean>(false);
  const [isClosePriceFocus, setIsClosePriceFocus] = useState<boolean>(false);
  const [isCloseLossFocus, setIsCloseLossFocus] = useState<boolean>(false);

  const isPriceUp = priceWillGo === Prediction.UP;

  const handleChangeTakePrice = (takePriceValue: string) => {
    dispatch(changeTakePriceAction(takePriceValue));
    const calculatedTakeProfit = calculateTakeProfit(entryPrice, takePriceValue, isPriceUp, betValue, multiplierValue);
    dispatch(changeTakeProfitAction(calculatedTakeProfit));
  };

  const handleChangeTakeProfit = (takeProfitValue: string) => {
    dispatch(changeTakeProfitAction(takeProfitValue));
    const calculatedTakePrice = calculateTakePrice(entryPrice, takeProfitValue, isPriceUp, betValue, multiplierValue);
    dispatch(changeTakePriceAction(calculatedTakePrice));
  };

  const handleChangeClosePrice = (closePriceValue: string) => {
    dispatch(changeClosePriceAction(closePriceValue));
    const calculatedCloseLoss = calculateCloseLoss(entryPrice, closePriceValue, isPriceUp, betValue, multiplierValue);
    dispatch(changeCloseLossAction(calculatedCloseLoss));
  };

  const handleChangeCloseLoss = (stopLossValue: string) => {
    dispatch(changeCloseLossAction(stopLossValue));
    const calculatedClosePrice = calculateClosePrice(entryPrice, stopLossValue, isPriceUp, betValue, multiplierValue);
    dispatch(changeClosePriceAction(calculatedClosePrice));
  };

  const handleTakePriceFocus = () => {
    setIsTakePriceFocus(true);
    setIsTakeProfitFocus(false);
  };

  const handleTakeProfitFocus = () => {
    setIsTakePriceFocus(false);
    setIsTakeProfitFocus(true);
  };

  const handleClosePriceFocus = () => {
    setIsClosePriceFocus(true);
    setIsCloseLossFocus(false);
  };

  const handleCloseLossFocus = () => {
    setIsClosePriceFocus(false);
    setIsCloseLossFocus(true);
  };

  useEffect(() => {
    if (isTakePriceFocus) {
      handleChangeTakePrice(takePrice);
    }
    if (isTakeProfitFocus) {
      handleChangeTakeProfit(takeProfit);
    }
    if (isClosePriceFocus) {
      handleChangeClosePrice(closePrice);
    }
    if (isCloseLossFocus) {
      handleChangeCloseLoss(closeLoss);
    }
  }, [betValue, multiplierValue, priceWillGo, entryPrice]);

  return (
    <>
      <TakeProfitSetting
        price={takePrice}
        profit={takeProfit}
        placeholder={'Price'}
        error={isErrorTakeCurrentPrice}
        errorText={errorTakeCurrentPriceText}
        onChangeTakePrice={handleChangeTakePrice}
        onChangeTakeProfit={handleChangeTakeProfit}
        onTakePriceFocus={handleTakePriceFocus}
        onTakeProfitFocus={handleTakeProfitFocus}
      />

      <CloseBetSetting
        price={closePrice}
        loss={closeLoss}
        error={isErrorCloseCurrentPrice || isErrorCloseBustPrice || isErrorCloseLoss}
        errorText={errorCloseText}
        placeholder={'Price'}
        onChangeClosePrice={handleChangeClosePrice}
        onChangeCloseLoss={handleChangeCloseLoss}
        onClosePriceFocus={handleClosePriceFocus}
        onCloseLossFocus={handleCloseLossFocus}
      />
    </>
  );
}
