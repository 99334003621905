import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { requester } from '../../../api/axiosInstance';
import { GetAssetsResponse, GetBarsRequest, GetBarsResponse } from '../types';

export const getAssets = createAsyncThunk<GetAssetsResponse>(
  '/assets/getAssets',
  async (): Promise<GetAssetsResponse> => {
    const response: AxiosResponse<GetAssetsResponse> = await requester.get(`/assets/active`);
    return response.data;
  },
);

export const getAssetVolume = createAsyncThunk<GetBarsResponse, GetBarsRequest>(
  '/assets/getAssetVolume',
  async (params: GetBarsRequest): Promise<GetBarsResponse> => {
    const response: AxiosResponse<GetBarsResponse> = await requester({ url: `/bars/crypto`, params: params });
    return response.data;
  },
);
