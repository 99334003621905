import { useCallback, useEffect } from 'react';
import { numericFormatter } from 'react-number-format';
import Icon from '@ant-design/icons';
import { notification } from 'antd';

import { InfoIcon } from '../assets/icons';
import { ClosedBet } from '../types/entities';

import styles from './messages.module.scss';

export function useShowMessage() {
  const [api, contextHolder] = notification.useNotification();
  useEffect(() => {
    notification.config({});
  }, []);

  const showSuccess = useCallback(
    (message: string) => {
      api.open({
        message: 'Success!',
        description: message,
        placement: 'topLeft',
        duration: 5,
        className: styles.containerSuccess,
        icon: <Icon component={InfoIcon} className={styles.icon} />,
      });
    },
    [api],
  );

  const showError = useCallback(
    (message: string) => {
      api.open({
        message: 'Error!',
        description: message,
        placement: 'topLeft',
        duration: 5,
        className: styles.containerError,
        icon: <Icon component={InfoIcon} className={styles.icon} />,
      });
    },
    [api],
  );

  const showCashOutMessage = useCallback(
    (bet: ClosedBet) => {
      const messageHeader = `Bet ${bet?.playerCurrency} closed at profit of ${Number(bet?.profit) > 0 ? '+' : '-'}$${numericFormatter(
        String(bet?.profit || 0),
        {
          thousandSeparator: true,
          decimalScale: 2,
          fixedDecimalScale: true,
          allowNegative: false,
        },
      )}`;
      const message = `Wager $${bet?.amount} Multiplier: x${bet?.multiplier}, Exit price: $${numericFormatter(
        String(bet?.closePrice || 0),
        {
          thousandSeparator: true,
          decimalScale: 2,
          fixedDecimalScale: true,
        },
      )}, Fee: $${numericFormatter(String(bet?.commissionFee || 0), {
        thousandSeparator: true,
        decimalScale: 2,
        fixedDecimalScale: true,
      })}`;
      api.open({
        message: messageHeader,
        description: message,
        placement: 'bottomLeft',
        duration: 7,
        className: styles.cashOutMessage,
        icon: <img src={bet.assetIcon} alt='' className={styles.icon} />,
      });
    },
    [api],
  );

  return { showSuccess, showError, contextHolder, showCashOutMessage };
}
