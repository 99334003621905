import React, { useCallback } from 'react';
import { ConfigProvider, Table } from 'antd';

import btc from '../../assets/images/btc.png';
import EmptyState from '../../components/EmptyState/EmptyState';
import FormattedAmount from '../../components/FormattedAmount/FormattedAmount';
import LeaderboardFilters from '../../components/LeaderboardFilters/LeaderboardFilters';
import BetInfoCell from '../../components/TableCells/BetInfoCell/BetInfoCell';
import BetPairCell from '../../components/TableCells/BetPairCell/BetPairCell';
import PlayerCell from '../../components/TableCells/PlayerCell/PlayerCell';
import PNLCell from '../../components/TableCells/PNLCell/PNLCell';
import RankCell from '../../components/TableCells/RankCell/RankCell';
import ROICell from '../../components/TableCells/ROICell/ROICell';
import useWindowSize from '../../hooks/useWindowSize';
import { BetStatus, LeaderboardItem, Prediction } from '../../types/entities';

import styles from '../../styles/table.module.scss';

export default function LeaderBoard() {
  const { isMobile } = useWindowSize();

  const renderEmptySate = useCallback(
    () => (
      <EmptyState
        description={[
          'It looks like the leaderboard is empty right now.',
          'Be the first to rise to the top by betting and earning your spot!',
        ]}
        header='No users yet / No bets yet'
      />
    ),
    [],
  );

  const columns = [
    {
      title: '#',
      key: 'rank',
      render: (_: any, bet: any, index: number) => <RankCell rank={index + 1} />,
      width: 48,
    },
    {
      title: 'Player',
      key: 'player',
      width: 160,
      render: (_: any, record: any) => (
        <PlayerCell playerName={record.playerName} playerImageUrl={record.playerImageUrl} />
      ),
    },
    {
      title: 'Bet',
      key: 'bet',
      width: 150,
      render: (_: any, record: any) => (
        <BetPairCell coin={record.playerCurrency} direction={record.prediction} image={btc} />
      ),
    },
    {
      title: 'Wager',
      dataIndex: 'amount',
      key: 'amount',
      width: 80,
      render: (amount: number) => (
        <span className={styles.highlighted}>
          <FormattedAmount amount={amount} />
        </span>
      ),
    },
    {
      title: 'Multiplier',
      dataIndex: 'multiplier',
      key: 'multiplier',
      render: (text: string) => <span className={styles.highlighted}>{`x${text}`}</span>,
      width: 60,
    },
    {
      title: 'Entry price',
      dataIndex: 'openPrice',
      key: 'openPrice',
      render: (price: number) => <FormattedAmount amount={price} />,
      width: 110,
    },
    {
      title: 'Bust price',
      dataIndex: 'bustPrice',
      key: 'bustPrice',
      render: (price: number) => <FormattedAmount amount={price} />,
      width: 110,
    },
    {
      title: 'Exit price',
      dataIndex: 'closePrice',
      key: 'closePrice',
      render: (price: number) => <FormattedAmount amount={price} />,
      width: 110,
    },
    {
      title: 'PNL',
      dataIndex: 'profit',
      key: 'profit',
      render: (value: number) => <PNLCell value={value} />,
      width: 80,
    },
    {
      title: 'ROI',
      dataIndex: 'roi',
      key: 'roi',
      render: (value: number) => <ROICell value={value} />,
      width: 80,
    },
    {
      title: '',
      render: (_: any, bet: any) => <BetInfoCell bet={bet} />,
      fixed: isMobile ? ('right' as any) : undefined,
      width: 36,
    },
  ];

  return (
    <ConfigProvider renderEmpty={renderEmptySate}>
      <div>
        {isMobile && <LeaderboardFilters />}
        <Table
          dataSource={data}
          columns={columns}
          rowClassName={styles.row}
          pagination={false}
          className={styles.table}
          rowKey='id'
          scroll={{ x: isMobile ? 500 : 955 }}
        />
      </div>
    </ConfigProvider>
  );
}

const data: LeaderboardItem[] = [
  {
    id: 133,
    playerCurrency: 'BTC',
    assetName: 'BTC/USD',
    prediction: Prediction.UP,
    amount: 32.3,
    multiplier: 100,
    openPrice: 62345.64,
    bustPrice: 59223.44,
    closePrice: 55423.44,
    profit: 2.45,
    roi: 1.34,
    playerId: 3443,
    playerName: 'SuperPlayer',
    playerImageUrl: 'https://picsum.photos/id/40/30',
    status: BetStatus.CLOSED,
  },
  {
    id: 233,
    playerCurrency: 'CDA',
    assetName: 'BTC/USD',
    prediction: Prediction.DOWN,
    amount: 34,
    multiplier: 10,
    openPrice: 335.64,
    bustPrice: 320.44,
    closePrice: 335.64,
    profit: 0,
    playerId: 3442,
    playerName: 'bet_crasher',
    playerImageUrl: 'https://picsum.photos/id/64/24',
    roi: 0,
    status: BetStatus.CLOSED,
  },
  {
    id: 3234,
    playerCurrency: 'BTC',
    assetName: 'BTC/USD',
    prediction: Prediction.UP,
    amount: 32.3,
    multiplier: 100,
    openPrice: 62345.64,
    bustPrice: 59223.44,
    closePrice: 62325.64,
    profit: -2.45,
    roi: -0.03,
    playerId: 3443,
    playerName: 'SuperPlayer',
    playerImageUrl: 'https://picsum.photos/id/137/24',
    status: BetStatus.CLOSED,
  },
  {
    id: 4,
    playerCurrency: 'BTC',
    assetName: 'BTC/USD',
    prediction: Prediction.UP,
    amount: 32.3,
    multiplier: 100,
    openPrice: 62345.64,
    bustPrice: 59223.44,
    closePrice: 62325.64,
    profit: -2.45,
    roi: -0.03,
    playerId: 3443,
    playerName: 'SuperPlayer',
    playerImageUrl: 'https://picsum.photos/id/137/24',
    status: BetStatus.CLOSED,
  },
  {
    id: 32343,
    playerCurrency: 'BTC',
    assetName: 'BTC/USD',
    prediction: Prediction.UP,
    amount: 32.3,
    multiplier: 100,
    openPrice: 62345.64,
    bustPrice: 59223.44,
    closePrice: 62325.64,
    profit: -2.45,
    roi: -0.03,
    playerId: 3443,
    playerName: 'SuperPlayer',
    playerImageUrl: 'https://picsum.photos/id/137/24',
    status: BetStatus.CLOSED,
  },
  {
    id: 4423,
    playerCurrency: 'BTC',
    assetName: 'BTC/USD',
    prediction: Prediction.UP,
    amount: 32.3,
    multiplier: 100,
    openPrice: 62345.64,
    bustPrice: 59223.44,
    closePrice: 62325.64,
    profit: -2.45,
    roi: -0.03,
    playerId: 3443,
    playerName: 'SuperPlayer',
    playerImageUrl: 'https://picsum.photos/id/137/24',
    status: BetStatus.CLOSED,
  },
  {
    id: 32393,
    playerCurrency: 'BTC',
    assetName: 'BTC/USD',
    prediction: Prediction.UP,
    amount: 32.3,
    multiplier: 100,
    openPrice: 62345.64,
    bustPrice: 59223.44,
    closePrice: 62325.64,
    profit: -2.45,
    roi: -0.03,
    playerId: 3443,
    playerName: 'SuperPlayer',
    playerImageUrl: 'https://picsum.photos/id/137/24',
    status: BetStatus.CLOSED,
  },
  {
    id: 44232,
    playerCurrency: 'BTC',
    assetName: 'BTC/USD',
    prediction: Prediction.UP,
    amount: 32.3,
    multiplier: 100,
    openPrice: 62345.64,
    bustPrice: 59223.44,
    closePrice: 62325.64,
    profit: -2.45,
    roi: -0.03,
    playerId: 3443,
    playerName: 'SuperPlayer',
    playerImageUrl: 'https://picsum.photos/id/137/24',
    status: BetStatus.CLOSED,
  },
];
