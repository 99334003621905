import React, { useEffect, useState } from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

import lottieData from '../../../assets/animations/step3.lottie';
import useWindowSize from '../../../hooks/useWindowSize';

import styles from '../Step.module.scss';

export default function Step3() {
  const { isMobile } = useWindowSize();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 50);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={styles.step}>
      <div className={styles.step_left}>
        <div className={styles.step_left_label}>Step 3</div>
        <h2 className={styles.step_left_title}>Choose a Wager and Multiplier</h2>
        <div className={styles.step_left_text}>
          Enter how much you want to bet, known as your <b>wager.</b> You can manually input the amount or use plus or
          minus to change price.
        </div>
        <div className={styles.step_left_text}>
          Choose a <b>multiplier</b> (leverage) to amplify your bet. The higher the multiplier, the bigger the potential
          reward (and risk).
        </div>
      </div>
      <div className={styles.lottie}>
        {isLoaded && (
          <DotLottieReact
            src={lottieData}
            autoplay
            loop
            style={{ width: isMobile ? '100%' : '400px', height: isMobile ? '100%' : '230px' }}
          />
        )}
      </div>
    </div>
  );
}
