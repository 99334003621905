import React from 'react';
import { Tabs, TabsProps } from 'antd';

import styles from './BetWidgetTabs.module.scss';

interface BetWidgetTabsProps {
  activeTab: string;
  onChangeActiveTab: any;
}

export default function BetWidgetTabs({ activeTab, onChangeActiveTab }: BetWidgetTabsProps) {
  const items: TabsProps['items'] = [
    {
      key: 'manual',
      label: 'Manual',
    },
    {
      key: 'auto',
      label: 'Auto',
      active: true,
    },
  ];

  const onChange = (key: string) => {
    onChangeActiveTab(key);
  };

  return (
    <Tabs
      defaultActiveKey='manual'
      items={items}
      activeKey={activeTab}
      onChange={onChange}
      className={styles.tabsWrapper}
    />
  );
}
