import React, { FC } from 'react';
import { Slider } from 'antd';

import styles from './CustomSlider.module.scss';

interface CustomSliderProps {
  value: number;
  onChangeSlider: any;
}

const MIN = 1;
const MAX = 1000;

const CustomSlider: FC<CustomSliderProps> = ({ value, onChangeSlider }) => {
  return (
    <>
      <div className={styles.customSlider}>
        <Slider
          min={MIN}
          max={MAX}
          defaultValue={1}
          value={value}
          onChange={onChangeSlider}
          tooltip={{ open: false }}
        />
        <div className={styles.scale}>
          {Array.from({ length: 7 }, (_, i) => i + 1).map((_, index) => (
            <div className={styles.scale_item} key={index}>
              <div className={styles.scale_item_line} />
            </div>
          ))}
        </div>
      </div>

      <div className={styles.bottom}>
        <div>
          <span>x1</span> Lower Risk
        </div>
        <div>
          Extreme <span>x1000</span>
        </div>
      </div>
    </>
  );
};

export default CustomSlider;
