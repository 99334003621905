import React from 'react';
import { NumericFormat } from 'react-number-format';
import cn from 'classnames';

import styles from './DashboardField.module.scss';

interface ControlBoardFieldProps {
  label: string;
  prefix?: string;
  value: string | number;
  thousandSeparator?: boolean;
  decimalScale?: number;
  maxValue?: number | string;
  minValue?: number | string;
  error?: boolean;
  errorText?: string;
  allowNegative?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function DashboardField({
  label,
  prefix,
  value,
  thousandSeparator = true,
  decimalScale = 2,
  maxValue = Infinity,
  minValue = -Infinity,
  error,
  errorText,
  allowNegative = false,
  onChange,
}: ControlBoardFieldProps) {
  return (
    <>
      <div className={styles.label}>{label}</div>
      <div className={cn(styles.input, prefix && styles.input_prefix)}>
        <NumericFormat
          thousandSeparator={thousandSeparator}
          decimalScale={decimalScale}
          fixedDecimalScale={true}
          allowNegative={allowNegative}
          prefix={''}
          inputMode='numeric'
          value={value}
          onChange={onChange}
          isAllowed={(values) => {
            const { formattedValue, floatValue = 0 } = values;
            return formattedValue === '' || (floatValue <= maxValue && floatValue >= minValue);
          }}
        />
        {prefix && <span>{prefix}</span>}
      </div>
      {error && <div className={styles.error}>{errorText}</div>}
    </>
  );
}
