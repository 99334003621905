export const upData = [
  { priceMove: '-100', key: '1' },
  { priceMove: '-50', key: '2' },
  { priceMove: '-10', key: '3' },
  { priceMove: '-5', key: '4' },
  { priceMove: '-1', key: '5' },
  { priceMove: '-0.5', key: '6' },
  { priceMove: '-0.1', key: '7' },
  { priceMove: '-0.05', key: '8' },
  { priceMove: '-0.01', key: '9' },
  { priceMove: '0', key: '10' },
  { priceMove: '0.01', key: '11' },
  { priceMove: '0.05', key: '12' },
  { priceMove: '0.1', key: '13' },
  { priceMove: '0.5', key: '14' },
  { priceMove: '1', key: '15' },
  { priceMove: '5', key: '16' },
  { priceMove: '10', key: '17' },
  { priceMove: '50', key: '18' },
  { priceMove: '100', key: '19' },
  { priceMove: '500', key: '20' },
  { priceMove: '1000', key: '21' },
];
