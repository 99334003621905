import React, { useState } from 'react';
import Icon from '@ant-design/icons';
import { Tabs, TabsProps } from 'antd';

import { ActiveBetsIcon, ClosedBetsIcon, LeaderboardIcon, PublicBetsIcon } from '../../assets/icons';
import useWindowSize from '../../hooks/useWindowSize';
import ActiveBets from '../../pages/ActiveBets/ActiveBets';
import ClosedBets from '../../pages/ClosedBets/ClosedBets';
import LeaderBoard from '../../pages/LeaderBoard/LeaderBoard';
import PublicBets from '../../pages/PublicBets/PublicBets';
import ActiveBetSettingsModal from '../ActiveBetSettingsModal/ActiveBetSettingsModal';
import BetInfoModal from '../BetInfo/BetInfoModal';
import LeaderboardFilters from '../LeaderboardFilters/LeaderboardFilters';

import styles from './TableTabs.module.scss';

export default function TableTabs() {
  const { isMobile } = useWindowSize();
  const [selectedTab, setSelectedTab] = useState('1');

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Active Bets',
      children: <ActiveBets />,
      icon: <Icon component={ActiveBetsIcon} className={styles.icon} />,
      className: styles.tabItem,
      destroyInactiveTabPane: true,
    },
    {
      key: '2',
      label: 'Closed Bets',
      children: <ClosedBets />,
      icon: <Icon component={ClosedBetsIcon} className={styles.icon} />,
      className: styles.tabItem,
      forceRender: false,
      destroyInactiveTabPane: true,
    },
    {
      key: '3',
      label: 'Public Bets',
      children: <PublicBets />,
      icon: <Icon component={PublicBetsIcon} className={styles.icon} />,
      className: styles.tabItem,
      forceRender: false,
      destroyInactiveTabPane: true,
    },
    {
      key: '4',
      label: 'Leaderboard',
      children: <LeaderBoard />,
      icon: <Icon component={LeaderboardIcon} className={styles.icon} />,
      className: styles.tabItem,
      forceRender: false,
      destroyInactiveTabPane: true,
    },
  ];

  const onChange = (key: string) => {
    setSelectedTab(key);
  };

  return (
    <>
      <Tabs
        defaultActiveKey='1'
        items={items}
        onChange={onChange}
        className={styles.barWrapper}
        animated={{ inkBar: true, tabPane: true }}
        more={{ visible: false, icon: null }}
        tabBarExtraContent={selectedTab === '4' && !isMobile && <LeaderboardFilters />}
      />
      <BetInfoModal />
      <ActiveBetSettingsModal />
    </>
  );
}
