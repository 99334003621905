import { createSlice } from '@reduxjs/toolkit';

import { BET_STEP } from '../../constants/constatnts';
import { BetType, CommissionType, Prediction } from '../../types/entities';

import { openBet } from './asyncThunks/slice';
import { BetSliceState } from './types';

const DEFAULT_MULTIPLIER_VALUE = 10;
const DEFAULT_BET_VALUE = 10;

const initialState: BetSliceState = {
  betValue: DEFAULT_BET_VALUE,
  multiplierValue: DEFAULT_MULTIPLIER_VALUE,
  priceWillGo: Prediction.UP,
  takePrice: '',
  takeProfit: '',
  closePrice: '',
  closeLoss: '',
  commissionType: CommissionType.PNL_FEE,
  betType: BetType.MANUAL,
  openBetPending: false,
};

const betSlice = createSlice({
  name: 'bet',
  initialState,
  reducers: {
    setBetValue: (state, action) => {
      state.betValue = action.payload;
    },
    incrementBetValue: (state) => {
      state.betValue = state.betValue + BET_STEP;
    },
    decrementBetValue: (state) => {
      if (state.betValue >= 1) {
        if (state.betValue < 2) {
          state.betValue = 1;
        } else {
          state.betValue = state.betValue - BET_STEP;
        }
      }
    },
    changeMultiplierValue: (state, action) => {
      state.multiplierValue = action.payload;
    },
    changePriceWillGoAction: (state, action) => {
      state.priceWillGo = action.payload;
    },
    changeTakePriceAction: (state, action) => {
      state.takePrice = action.payload;
    },
    changeTakeProfitAction: (state, action) => {
      state.takeProfit = action.payload;
    },
    changeClosePriceAction: (state, action) => {
      state.closePrice = action.payload;
    },
    changeCloseLossAction: (state, action) => {
      state.closeLoss = action.payload;
    },
    changeCommissionTypeAction: (state, action) => {
      state.commissionType = action.payload;
    },
    changeBetTypeAction: (state, action) => {
      state.betType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(openBet.pending, (state, action) => {
      state.openBetPending = true;
    });
    builder.addCase(openBet.fulfilled, (state, action) => {
      state.openBetPending = false;
      state.betValue = DEFAULT_BET_VALUE;
      state.multiplierValue = DEFAULT_MULTIPLIER_VALUE;
      state.takePrice = '';
      state.takeProfit = '';
      state.closePrice = '';
      state.closeLoss = '';
    });
    builder.addCase(openBet.rejected, (state, action) => {
      state.openBetPending = false;
    });
  },
});

export const {
  setBetValue,
  incrementBetValue,
  decrementBetValue,
  changeMultiplierValue,
  changePriceWillGoAction,
  changeTakePriceAction,
  changeTakeProfitAction,
  changeClosePriceAction,
  changeCloseLossAction,
  changeCommissionTypeAction,
  changeBetTypeAction,
} = betSlice.actions;
export const betReducer = betSlice.reducer;
