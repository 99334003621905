export const theme = {
  colorBorderSecondary: '#000',
  fontFamily: 'Inter, sans-serif',
  components: {
    Tabs: {
      itemColor: '#9096AF',
      itemActiveColor: '#D7D8E0',
      itemHoverColor: '#9096AF',
      itemSelectedColor: '#D7D8E0',
      titleFontSize: 14,
      inkBarColor: '#D7D8E0',
      fontFamily: 'Inter, sans-serif',
      horizontalItemPadding: '7px 0',
    },
    Table: {
      headerBg: '#35394a',
      headerColor: '#9096AF',
      headerBorderRadius: 8,
      headerSplitColor: 'transparent',
      borderColor: '#4D505E',
      cellPaddingBlock: 8,
      colorBgContainer: '#2E2F37',
    },
    Input: {
      activeShadow: 'none',
      hoverBorderColor: '#2398F8',
    },
    Modal: {
      borderRadiusLG: 8,
      contentBg: '#21242E',
      colorIcon: '#ffffff',
    },
    Button: {
      defaultShadow: 'none',
      defaultBorderColor: 'transparent',
      defaultHoverBorderColor: 'transparent',
    },
    Notification: {
      width: 515,
      borderRadiusLG: 8,
      colorBgElevated: 'transparent',
      colorIcon: '#ffffff',
    },
    Splitter: {
      splitBarSize: 3,
      splitTriggerSize: 8,
      controlItemBgActive: 'transparent',
      controlItemBgActiveHover: 'transparent',
      colorFill: '#9096AF',
    },
    Collapse: {
      headerPadding: 0,
      contentPadding: 0,
    },
    Dropdown: {
      paddingBlock: 8,
      colorBgElevated: '#15181E',
      controlItemBgHover: '#15181E',
      controlItemBgActive: '#4B5063',
      controlItemBgActiveHover: '#4B5063',
      colorText: '#9096AF',
      colorPrimary: '#fff',
    },
    Menu: {
      controlItemBgHover: '#15181E',
      controlItemBgActive: '#21242E',
      controlItemBgActiveHover: '#21242E',
      colorText: '#9096AF',
      colorPrimary: '#9096AF',
      itemBg: '#35394A',
      itemMarginInline: 0,
      itemMarginBlock: 0,
      itemPaddingInline: 0,
    },
    Select: {
      paddingBlock: 8,
      colorBgElevated: '#15181E',
      controlItemBgHover: '#4B5063',
      controlItemBgActive: '#35394A',
      controlItemBgActiveHover: '#4B5063',
      colorText: '#9096AF',
      colorPrimary: '#fff',
      activeBorderColor: 'transparent',
      activeOutlineColor: 'transparent',
      hoverBorderColor: 'transparent',
      colorBorder: 'transparent',
      colorIcon: '#ffffff',
      colorItemTextSelected: '#ffffff',
      colorBgContainer: '#21242E',
      optionSelectedColor: '#FFFFFF',
      optionHeight: 38,
      optionLineHeight: 2,
    },
    Checkbox: {
      controlInteractiveSize: 20,
      fontFamily: 'Inter, sans-serif',
      colorText: '#9096AF',
      colorPrimary: '#12B76A',
      colorWhite: '#12B76A',
      colorPrimaryHover: '#9096AF',
      colorPrimaryBorder: '#9096AF',
      fontSize: 14,
    },
    Steps: {
      colorPrimary: '#c0180c',
      padding: 0,
    },
    Tooltip: {
      colorBgSpotlight: '#21242E',
      colorTextLightSolid: '#D7D8E0',
      boxShadowSecondary: '0 0 0 1px #35394A',
      fontSize: 12,
      sizePopupArrow: 18,
      fontFamily: 'Inter, sans-serif',
    },
  },
  token: {
    colorPrimary: '#0E8A50',
    colorBgContainer: '#2E2F37',
    colorText: '#D7D8E0',
  },
};
