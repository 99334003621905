import { store } from '../../store';
import { setCurrentBarColor } from '../../store/assets/slices';
import { EventStreamContentType, fetchEventSource } from '@microsoft/fetch-event-source';
import { API } from '../../api/uri';
import { FatalError, RetriableError } from '../../helpers/EventSourceErrors';

const channelToSubscription = new Map();
let abortSignal;
const oneMinute = 1000 * 60;

function getTimeDelta(resolution) {
  switch (resolution) {
    case '1T': {
      return 1000;
    }
    case '5S': {
      return 5000;
    }
    case '15S': {
      return 15000;
    }
    case '30S': {
      return 30000;
    }
    case '1': {
      return oneMinute;
    }
    case '5': {
      return oneMinute * 5;
    }
  }
}

function getNextBarTime(barTime, resolution) {
  switch (resolution) {
    case '1T': {
      return new Date(barTime + 200).getTime();
    }
    case '5S': {
      return new Date(barTime + 5000).getTime();
    }
    case '15S': {
      return new Date(barTime + 15000).getTime();
    }
    case '30S': {
      return new Date(barTime + 30000).getTime();
    }
    case '1': {
      return new Date(barTime + oneMinute).getTime();
    }
    case '5': {
      return new Date(barTime + oneMinute * 5).getTime();
    }
    case '15': {
      return new Date(barTime + oneMinute * 15).getTime();
    }
    case '30': {
      return new Date(barTime + oneMinute * 30).getTime();
    }
    case '60': {
      const date = new Date(barTime);
      date.setHours(date.getHours() + 1);
      return new Date(barTime + oneMinute * 60).getTime();
    }
    case '240': {
      return new Date(barTime + oneMinute * 240).getTime();
    }
    case '1D': {
      const date = new Date(barTime);
      date.setDate(date.getDate() + 1);
      return date.getTime();
    }
    default: {
      const date = new Date(barTime);
      date.setDate(date.getDate() + 1);
      return date.getTime();
    }
  }
}

function getNewBar(resolution, lastItemBar, tradePrice, tradeTime) {
  const nextBarTime = getNextBarTime(lastItemBar.time, resolution);
  let bar;
  let newBarTime = nextBarTime;
  if (tradeTime >= nextBarTime) {
    const delta = getTimeDelta(resolution);
    if (tradeTime - nextBarTime > delta) {
      newBarTime = tradeTime - (tradeTime % delta);
    }

    bar = {
      time: newBarTime,
      open: tradePrice,
      high: tradePrice,
      low: tradePrice,
      close: tradePrice,
    };
    if (resolution !== '1T') {
      store.dispatch(setCurrentBarColor(tradePrice > lastItemBar.close ? 'GREEN' : 'RED'));
    }
  } else {
    bar = {
      ...lastItemBar,
      high: Math.max(lastItemBar.high, tradePrice),
      low: Math.min(lastItemBar.low, tradePrice),
      close: tradePrice,
    };
    if (resolution !== '1T') {
      store.dispatch(setCurrentBarColor(tradePrice > lastItemBar.open ? 'GREEN' : 'RED'));
    }
  }
  return bar;
}

function getSmoothBars(lastBar, tradePrice, tradeTime) {
  const N = 5;
  const timeDelta = Math.ceil(200 / N);
  const priceDelta = (tradePrice - lastBar.close) / N;
  // console.log({ lastBar, tradeTime, tradePrice });
  const bars = new Array(N).fill(lastBar).map((item, index) => {
    if (index === N - 1) {
      return {
        bar: {
          time: tradeTime,
          open: tradePrice,
          high: tradePrice,
          low: tradePrice,
          close: tradePrice,
        },
        delta: 200,
      };
    } else {
      const rand = Math.random() - 0.5;
      const price = lastBar.close + priceDelta * (index + 1) + priceDelta * rand;
      return {
        delta: timeDelta * (index + 1),
        bar: {
          time: tradeTime,
          // time: lastBar.time + timeDelta * (index + 1),
          open: tradePrice,
          high: price,
          low: price,
          close: price,
        },
      };
    }
  });
  // console.log(bars);
  return bars;
}

export function subscribeOnStream(
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscribeUID,
  onResetCacheNeededCallback,
  lastBar,
) {
  if (abortSignal) {
    abortSignal.abort();
  }
  // onResetCacheNeededCallback();
  abortSignal = new AbortController();

  fetchEventSource(`${API.ASSET_PRICE_UPDATE}/${symbolInfo.full_name}/updates`, {
    openWhenHidden: true,
    keepalive: true,
    async onopen(response) {
      if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
        return;
      } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
        throw new FatalError();
      } else {
        throw new RetriableError();
      }
    },
    signal: abortSignal.signal,
    onclose() {
      // console.log('Close connection');
      throw new RetriableError();
    },
    onerror(err) {
      console.log('Connection error');
      if (err instanceof FatalError) {
        // throw err;
        console.log('Fatal error on chart datafeed subscription');
      } else {
        console.log('Try to reconnect');
      }
    },
    onmessage(message) {
      const data = JSON.parse(message.data);
      const tradePrice = data.lp;
      const tradeTime = data.t;

      const subscriptionItem = channelToSubscription.get(subscribeUID);
      if (subscriptionItem === undefined) return;

      const lastItemBar = subscriptionItem.lastBar;
      if (!subscriptionItem.lastBar) return;
      if (resolution !== '1T') {
        const bar = getNewBar(resolution, lastItemBar, tradePrice, tradeTime);
        subscriptionItem.lastBar = bar;
        subscriptionItem.handlers.callback(bar);
      } else {
        const smoothArr = getSmoothBars(lastItemBar, tradePrice, tradeTime);
        smoothArr.forEach((item) => {
          setTimeout(() => {
            // console.log(item.bar);
            if (subscriptionItem.lastBar.time <= item.bar.time) {
              subscriptionItem.lastBar = item.bar;
              subscriptionItem.handlers.callback(item.bar);
            }
          }, item.delta);
        });
      }
    },
  });

  const handler = {
    id: subscribeUID,
    callback: onRealtimeCallback,
  };

  let subscriptionItem = channelToSubscription.get(subscribeUID);
  if (subscriptionItem) {
    // already subscribed to the channel, use the existing subscription
    subscriptionItem.handlers = handler;
    return;
  }
  subscriptionItem = {
    subscribeUID,
    resolution,
    lastBar,
    handlers: handler,
  };

  channelToSubscription.set(subscribeUID, subscriptionItem);
}

export function unsubscribeFromStream(subscribeUID) {
  if (abortSignal) {
    abortSignal.abort();
  }
}
