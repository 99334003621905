import { useEffect, useRef, useState } from 'react';

import { useTypedSelector } from '../../hooks/useTypedSelector';

const useEntryPrice = () => {
  const hasTriggeredRef = useRef(false);
  const [entryPriceValue, setEntryPriceValue] = useState<number>(0);
  const { currentAsset } = useTypedSelector((state) => state.assets);

  useEffect(() => {
    if (Number(currentAsset?.lastPrice) > 0 && !hasTriggeredRef.current) {
      setEntryPriceValue(Number(currentAsset?.lastPrice));
      hasTriggeredRef.current = true;
    }
  }, [currentAsset?.lastPrice]);

  return { entryPriceValue, setEntryPriceValue };
};

export default useEntryPrice;
