import React from 'react';

import styles from './PlayerCell.module.scss';

type PlayerCellProps = {
  playerImageUrl: string;
  playerName: string;
};

export default function PlayerCell({ playerImageUrl, playerName }: PlayerCellProps) {
  return (
    <span className={styles.cell}>
      {/*<img src={playerImageUrl} alt='' className={styles.avatar} />*/}
      <span>{playerName}</span>
    </span>
  );
}
