import React from 'react';
import { Radio, RadioChangeEvent } from 'antd';
import cn from 'classnames';

import { InfoIcon } from '../../../../assets/icons';
import { CommissionType } from '../../../../types/entities';

import styles from './RadioBlock.module.scss';

interface RadioBlockProps {
  selected: string;
  onChangeRadio: (e: RadioChangeEvent) => void;
}

export default function RadioBlock({ selected, onChangeRadio }: RadioBlockProps) {
  return (
    <>
      <Radio.Group onChange={onChangeRadio} value={selected} className={styles.radioGroup}>
        <Radio value={CommissionType.PNL_FEE} className={styles.customRadio}>
          <div className={styles.customRadio_inner}>
            <span
              className={cn(
                styles.customRadio_inner_label,
                selected === CommissionType.PNL_FEE && styles.customRadio_inner_label_checked,
              )}
            >
              PNL Fee
            </span>
            <InfoIcon />
          </div>
        </Radio>
        <Radio value={CommissionType.FLAT_FEE} className={styles.customRadio}>
          <div className={styles.customRadio_inner}>
            <span
              className={cn(
                styles.customRadio_inner_label,
                selected === CommissionType.FLAT_FEE && styles.customRadio_inner_label_checked,
              )}
            >
              Flat Fee
            </span>
            <InfoIcon />
          </div>
        </Radio>
      </Radio.Group>
      <p className={styles.text}>A portion of your profits (if any) is deducted when you close the trade.</p>
    </>
  );
}
