import React from 'react';
import cn from 'classnames';

import styles from './AnimatedButton.module.scss';

type AnimatedButtonProps = {
  label?: string;
  icon?: React.ReactNode;
  containerClassName?: any;
  type: 'primaryGreen' | 'primaryRed';
  onClick: () => void;
  disabled?: boolean;
};

export default function AnimatedButton({
  type,
  containerClassName,
  icon,
  label,
  onClick,
  disabled,
}: AnimatedButtonProps) {
  return (
    <div className={cn(styles.container, containerClassName)}>
      <span
        className={cn(
          styles.outer,
          type === 'primaryGreen' && styles.outer_green,
          type === 'primaryRed' && styles.outer_red,
          disabled && styles.outer_disabled,
        )}
      />
      <button
        onClick={onClick}
        disabled={disabled}
        className={cn(
          styles.button,
          type === 'primaryGreen' && styles.button_green,
          type === 'primaryRed' && styles.button_red,
        )}
      >
        <span>{icon && icon}</span>
        <span>{label}</span>
      </button>
    </div>
  );
}
