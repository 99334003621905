import React from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

import lottieData from '../../../assets/animations/step2.lottie';
import useWindowSize from '../../../hooks/useWindowSize';

import styles from '../Step.module.scss';

export default function Step2() {
  const { isMobile } = useWindowSize();
  return (
    <div className={styles.step}>
      <div className={styles.step_left}>
        <div className={styles.step_left_label}>Step 2</div>
        <h2 className={styles.step_left_title}>Bet on the price going up or down</h2>
        <div className={styles.step_left_text}>
          Start by selecting whether you think the price of the cryptocurrency (like Bitcoin or Ethereum) will{' '}
          <b>go up</b> or <b>go down.</b> This is your prediction.
        </div>
        <div className={styles.step_left_subtext}>
          If you believe the price will rise, select <span className={styles.step_left_subtext_green}>Up</span>. If you
          think it will drop, select <span className={styles.step_left_subtext_red}>Down</span>.
        </div>
      </div>
      <div className={styles.lottie}>
        <DotLottieReact
          src={lottieData}
          loop
          autoplay
          style={{ width: isMobile ? '100%' : '400px', height: isMobile ? '100%' : '230px' }}
        />
      </div>
    </div>
  );
}
